import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "./Sidebarlink";

function Notification() {
  useEffect(() => {
    getNotifications();
  }, [0]);

  const [loader, setLoader] = useState(false);
  const [notifications, setNotification] = React.useState("");
  const [currentpage, Setcurrentpage] = useState(1);
  const [totalpage, Settotalpage] = useState(0);
  const recordpage = 5;
  const pagerange = 5;
  const historychanges = (pagenumber) => {
    getNotifications(pagenumber);
    Setcurrentpage(pagenumber);
  };
  const getNotifications = async (page) => {
    try {
      var data = {
        payload: {
          FilPerpage: 5,
          FilPage: page,
        },
        apiUrl: apiService.notifications,
        payload: {
          FilPerpage: 5,
          FilPage: page,
        },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
        Settotalpage(resp.total);
      } else {
      }
    } catch (error) {}
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div class="col-lg-12 d-flex justify-content-between align-items-center pt-3">
                          <div className="headin_deposur_s">
                            <h3>Notification</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="tavle_transactio">
                    <div className="padding_section">
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-lg-10 shadow">
                            <div className="market_tabel1 w-100 mt-0 p-3">
                              <div className="market_section">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead >
                                      <tr>
                                        <th>S.No</th>
                                        <th>Date & Time</th>
                                        <th>Message</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {notifications &&
                                      notifications.length > 0 ? (
                                        notifications.map((item, i) => {
                                          return (
                                            <>
                                              <tr>
                                                <td className="text-dark">{i + 1}</td>
                                                <td className="text-dark">
                                                  {Moment(
                                                    item.createdAt
                                                  ).format(
                                                    "MMMM Do YYYY, h:mm:ss a"
                                                  )}
                                                </td>
                                                <td className="text-dark">{item.message}</td>
                                              </tr>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td>Data's No Found</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                  {notifications.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentpage}
                                      itemsCountPerPage={recordpage}
                                      totalItemsCount={totalpage}
                                      pageRangeDisplayed={pagerange}
                                      onChange={historychanges}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Notification;
