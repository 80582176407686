import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    email: "",
  };

  const initialFormValue1 = {
    password: "",
    confirmPassword: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [confirmPasswordValidate, setconfirmPasswordValidate] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [activeStatus, seractiveStatus] = useState(false);
  const [dpliutl, setdpliutl] = useState("");

  const [validationnErr1, setvalidationnErr1] = useState("");

  const { email } = formValue;
  const { password, confirmPassword } = formValue1;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };
  const handleChange1 = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData1 = { ...formValue1, ...{ [name]: value } };
    setFormValue1(formData1);
    validate1(formData1);
  };

  useEffect(() => {
    linkverify();
  }, [0]);

  const linkverify = async (values) => {
    var dpliutl = window.location.href.split("_")[1];
    if (dpliutl) {
      setdpliutl(dpliutl);
      var obj = {
        link: dpliutl,
      };
      var data = {
        apiUrl: apiService.verifyForgotpasslink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        seractiveStatus(true);
        toast.success(resp.Message);
        setvalidationnErr("")
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const validate1 = async (values) => {
    const errors1 = {};

    if (!values.password) {
      errors1.password = "Password is a required field";
      setpasswordValidate(true);
    }

    if (!values.confirmPassword) {
      errors1.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    }

    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      // errors.password = 'Password and confirm password does not match';
      errors1.confirmPassword = "Password and confirm password does not match";
    }

    setvalidationnErr1(errors1);
    return errors1;
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.email != "" && emailValidateref.current == false) {
      var obj = {
        email: formValue.email,
      };
      var data = {
        apiUrl: apiService.changepswdlink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      formValue.email = "";
      console.log(resp, "==-=-resp");
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    }
  };

  const formSubmitchange = async () => {
    validate1(formValue1);
    if (formValue1.password != "" && formValue1.confimPassword != "") {
      var obj = {
        password: formValue1.password,
        confimPassword: formValue1.confirmPassword,
        id: dpliutl,
      };

      console.log(obj, "=-=-=-=-=-=-=-==-=");
      var data = {
        apiUrl: apiService.resetpassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form row">
          <div className="framen_login col-lg-6">
            <div className="contnt_login">
              <h2>Swapo</h2>
              <p>Trade Instantly & Pool Tokens to Earn Rewards</p>
            </div>
          </div>
          <div className="frame_fprm col-lg-6">
            <div className="row justify-content-center">
              <div className="col-lg-12">
              {activeStatus == false ? (
                  <div className="card_login">
                    <h3>Forget Password</h3>
                    <div>
                      <div class="form_login_input">
                        <label>Email</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                          {emailValidateref.current == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr.email}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                      </div>

                      <div class="form_login_input">
                        {buttonLoader == false ? (
                          <Button onClick={formSubmit}>Submit</Button>
                        ) : (
                          <Button>Loading...</Button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card_login">
                    <h3>Reset Password</h3>
                    <div>
                      <div class="form_login_input">
                        <label>Enter New Password</label>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="New Password"
                          name="password"
                          value={password}
                          onChange={handleChange1}
                        />
                        <div>
                          {passwordValidate == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr1.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="form_login_input">
                        <label>Confirm New Password</label>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Confirm New Password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={handleChange1}
                        />
                        <div>
                          {confirmPasswordValidate == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr1.confirmPassword}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div class="form_login_input">
                        {buttonLoader == false ? (
                          <Button onClick={formSubmitchange}>
                            Change Password
                          </Button>
                        ) : (
                          <Button>Loading...</Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>

      </main>
      <Footer />
    </div>
  );
}

export default Home;
