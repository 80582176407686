import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebarlink";


import Buy from "./Buy"
import Sell from "./Sell"
function Home() {
  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>

          
            <div className="css-1h690ep">
              <div className="section_bg_ash">
                <div className="section_selec_ash">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="heading_titlr">Buy Crypto</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                <div className="inner_conteten col-lg-6 mt-4 border rounded px-3">
                  <div className="order_tabs">
                    <ul class="nav nav-tabs row border-bottom rounded pb-1">
                      <li class="active col-lg-12">
                        <a data-toggle="tab" href="#home1" className="active w-100 text-center">
                        Buy
                        </a>
                      </li>
                      {/* <li class="col-lg-6">
                        <a data-toggle="tab" href="#menu2" className="w-100 text-center">
                          Sell
                        </a>
                      </li> */}
                    </ul>
                    <div class="tab-content ">
                      <div id="home1" class="tab-pane fade in active show">
                          <Buy />
                      </div>
                      {/* <div id="menu2" class="tab-pane fade">
                      <Sell />                        
                      </div> */}
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
