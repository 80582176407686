import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IconPencil } from "@tabler/icons-react";
import { IconArrowBack } from "@tabler/icons-react";
import { IconCopy } from '@tabler/icons-react';
import validator from "validator";

function Home() {

  const textRef = useRef(null);

  const handleCopy1 = () => {
    const textToCopy = textRef.current.value; 
    navigator.clipboard.writeText(textToCopy)
        .then(() => {
            toast.success("Code Copied");
        })
        .catch((error) => {
            toast.error('Failed to copy code');
        });
};


  
  useEffect(() => {
    getTfastatus();
    profile();
  }, []);
  const navigate = useNavigate();
  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };

  const initialValue = {
    Name: "",
    phoneNumber: "",
    Address: "",
    imageLable: "",
    BussinessName:"",
    Website:"",
    payment:""
  };
  const [formdata, setFormdata] = useState(initialValue);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaUrl, settfaUrl] = useState("");
  const [userMail, setuserMail] = useState("");
  const [tfaCode, settfaCode] = useState("");
  const [tfaStatus, settfaStatus] = useState("");
  const [ValidationErrors, setValidationErrors] = useState({});
  const [oldpassvalidate, setoldpassvalidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [confirmPasswordValidate, setconfirmPasswordValidate] = useState(false);
  const [passconfNotmatch, setpassconfNotmatch] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passhide1, setpasshide1] = useState(false);
  const [inputtype1, setinputtype1] = useState("password");
  const [passhide2, setpasshide2] = useState(false);
  const [inputtype2, setinputtype2] = useState("password");
  const [passhide3, setpasshide3] = useState(false);
  const [inputtype3, setinputtype3] = useState("password");
  const [type, settype] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [title, settitle] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [address, setaddress] = useState("");
  const [site, setsite] = useState("");
  const [editStatus, seteditStatus] = useState(false);
  const [Profile, setprofile] = useState("");
  const [imagelable, setimagelable] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [phoneNumberErr, setphoneNumberErr] = useState(false);
  const [BussinessNameErr, setBussinessNameErr] = useState(false);
  const [WebsiteErr, setWebsiteErr] = useState(false);
  const [addressErr, setaddressErr] = useState(false);
  const [imagelableErr, setimagelableErr] = useState(false);
  const { oldpassword, password, confirmPassword } = formValue;
  const { Name, phoneNumber, Address, imageLable ,BussinessName,Website} = formdata;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    // validate(formData);
  };
  const handleProfileChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formdata, ...{ [name]: value } };
    setFormdata(formData);
    // validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.oldpassword) {
      errors.oldpassword = "Old Password is a required field";
      setoldpassvalidate(true);
    } else if (
      validator.isStrongPassword(formValue.oldpassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setoldpassvalidate(false);
    } else {
      errors.oldpassword = "Enter strong    Old Password";
      setoldpassvalidate(true);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (
      validator.isStrongPassword(formValue.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setpasswordValidate(false);
    } else {
      errors.password = "Enter strong New password";
      setpasswordValidate(true);
    }

    if (values.password == values.oldpassword) {
      errors.password = "New and old Password are same";
      setpasswordValidate(true);
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password and confirm password does not match";
      setpassconfNotmatch(true);
    } else {
      setpassconfNotmatch(false);
    }
    if (values.Name == "") {
      console.log("jhdfjsadhkjh");
      errors.Name = "Name is required";
      setNameErr(true);
    } else {
      setNameErr(false);
    }
    if (values.phoneNumber == "") {
      errors.phoneNumber = "phoneNumber is required";
      setphoneNumberErr(true);
    } else {
      setphoneNumberErr(false);
    }
    if (values.Address == "") {
      errors.Address = "Address is required";
      setaddressErr(true);
    } else {
      setaddressErr(false);
    }
    if (imagelable == "") {
      errors.imageLable = "Image is required";
      setimagelableErr(true);
    } else {
      setimagelableErr(false);
    }
    if (values.Website == "") {
      errors.Website = "Website is required";
      setWebsiteErr(true);
    } else {
      setWebsiteErr(false);
    }
    if (values.BussinessName == "") {
      errors.BussinessName = "Bussine Name is required";
      setBussinessNameErr(true);
    } else {
      setBussinessNameErr(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async (payload) => {
    validate(formValue);
    if (
      formValue.oldpassword != "" &&
      formValue.oldpassword != formValue.password &&
      formValue.password != "" &&
      formValue.password == formValue.confirmPassword &&
      formValue.confirmPassword != ""
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      console.log(resp.email);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        localStorage.clear();
        navigate("/");
        window.location.reload(true);
      } else {
        toast.error(resp.Message);
      }
    } else {
      console.log("all field requird");
    }
  };

  const oldPassword = (data1) => {
    if (data1 == "hide") {
      setpasshide1(true);
      setinputtype1("text");
    } else {
      setpasshide1(false);
      setinputtype1("password");
    }
  };

  const newPassword = (data2) => {
    if (data2 == "hide") {
      setpasshide2(true);
      setinputtype2("text");
    } else {
      setpasshide2(false);
      setinputtype2("password");
    }
  };

  const conPassword = (data3) => {
    if (data3 == "hide") {
      setpasshide3(true);
      setinputtype3("text");
    } else {
      setpasshide3(false);
      setinputtype3("password");
    }
  };

  const getTfastatus = async () => {
    var data = {
      apiUrl: apiService.getTfaDetials,
    };

    var resp = await getMethod(data);
    console.log(resp, "=-=-=-resp");
    settfaUrl(resp.data.tfa_url);
    setuserMail(resp.data.email);
    settfaStatus(resp.data.tfastatus);
    localStorage.setItem("tfa_status", resp.data.tfastatus);
  };

  const profile = async () => {
    try {
      var data = {
        apiUrl: apiService.profiledetail,
      };

      var resp = await getMethod(data);
      console.log(resp.Message);
      if (resp.status === true) {
        console.log(resp.Message, "data");
        setemail(resp.Message.email);
        setname(resp.Message.name);
        setprofile(resp.Message.profile);
        formdata.Name = resp.Message.name;
        settitle(resp.Message.BussinessName);
        setsite(resp.Message.website);
        formdata.Website = resp.Message.website;
        formdata.BussinessName = resp.Message.BussinessName;
        formdata.payment = resp.Message.payment;
        settype(resp.Message.tyep);
        console.log(resp.Message.type);
        console.log(type);
        setusername(resp.Message.username);
        setphonenumber(resp.Message.mobileNumber);
        formdata.phoneNumber = resp.Message.mobileNumber;
        console.log(phonenumber);
        setaddress(resp.Message.address);
        console.log(resp.Message.address,"address");

        setimagelable(resp.Message.profile);
        formdata.Address = resp.Message.address;

        console.log(address);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const edituserprofile = () => {
    seteditStatus(true);
  };

  const back = () => {
    seteditStatus(false);
  };

  const imageUpload = (val) => {
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"
    ) {
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "fgzjzpjf");
      data.append("cloud_name", "dysf7ocz6");
      console.log("formdata===", data);
      fetch("https://api.cloudinary.com/v1_1/dysf7ocz6/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data.secure_url);
          setprofile(data.secure_url);
          setimagelable(fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const EditProfile = async () => {
    validate(formdata);
    if (
      nameErr == false &&
      phoneNumberErr == false &&
      addressErr == false &&
      imagelableErr == false &&
      WebsiteErr == false &&
      BussinessNameErr == false 
    ) {
      console.log(formdata,"formdata")
      var obj = {
        data: formdata,
        Profile,
      };
      var data = {
        apiUrl: apiService.editUserprofile,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      if (resp.status == true) {
        setbuttonLoader(false);
        toast.success(resp.Message);
        profile();
      } else {
        toast.error(resp.Message);
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>

          
            <div className="css-1h690ep">
              <div className="section_bg_ash">
                <div className="section_selec_ash">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="heading_titlr">User Profile</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inner_conteten">
                  <div className="order_tabs">
                    <ul class="nav nav-tabs">
                      <li class="active">
                        <a data-toggle="tab" href="#home1" className="active">
                          User Profile
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#menu2">
                          Change Password
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content ">
                      <div id="home1" class="tab-pane fade in active show">
                        {editStatus != true ? (
                          <div className="row m-5 p-5 shadow">
                            <div className="col-md-4">
                              <div className="d-flex justify-content-center mt-5 rounded">
                                {Profile == "" ? (
                                  <img
                                    src={require("../Image/profile.png")}
                                    className="profile_img mx-auto block "
                                  />
                                ) : (
                                  <img
                                    src={Profile}
                                    className="profile_img mx-auto block "
                                  />
                                )}
                              </div>

                              <h5 className="text-center">{name}</h5>
                              <h5 className="text-center">{email}</h5>
                            </div>
                            <div className="col-md-8">
                              <div data-toggle="tab" href="#editprofile">
                                <IconPencil
                                  onClick={edituserprofile}
                                ></IconPencil>
                              </div>
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th> UserName</th>
                                    <th>{name}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>Email</th>
                                    <th>{email}</th>
                                  </tr>
                                </tbody>
                                <thead>
                                  <tr>
                                    <th>phoneNumber</th>
                                    <th>
                                      {phonenumber == ""
                                        ? "NotUpload"
                                        : phonenumber}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>Address</th>
                                    <th>
                                      {address == "" ? "NotUpload" : address}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div className="row m-5 p-5 shadow">
                            <div className="col-md-4">
                              <IconArrowBack onClick={back}></IconArrowBack>
                              <div className="d-flex justify-content-center mt-5 rounded">
                                {Profile == "" ? (
                                  <img
                                    src={require("../Image/profile.png")}
                                    className="profile_img mx-auto block "
                                  />
                                ) : (
                                  <img
                                    src={Profile}
                                    className="profile_img mx-auto block "
                                  />
                                )}
                              </div>

                              <h5 className="text-center">{name}</h5>
                              <h5 className="text-center">{email}</h5>
                            </div>
                            <div className="col-md-8  crypto_coin_selec pa-_opo">
                              <label>Profile</label>
                              <div class="custom-file">
                                <input
                                  type="file"
                                  class="custom-file-input"
                                  id="customFile"
                                  onChange={(e) =>
                                    imageUpload(e.target.files[0])
                                  }
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  {imagelable == ""
                                    ? " choose Image"
                                    : imagelable}
                                </label>
                                {imagelableErr == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {validationnErr.imageLable}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                              <label className="my-3">
                                Name
                                <small>4/25characters</small>
                              </label>
                              <input
                                type="text"
                                name="Name"
                                required=""
                                className="pan_number"
                                placeholder="Name"
                                minLength={4}
                                maxLength={25}
                                value={formdata.Name}
                                onChange={handleProfileChange}
                              ></input>
                              {nameErr == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {validationnErr.Name}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                              <label className="my-3">Email</label>
                              <input
                                type="text"
                                name="Email"
                                className="pan_number text-muted"
                                placeholder="Email "
                                value={email}
                              ></input>
                              <label className="my-3">
                                phoneNumber
                                <small>6/15characters</small>
                              </label>
                              <input
                                type="text"
                                name="phoneNumber"
                                required=""
                                className="pan_number"
                                placeholder="phoneNumber"
                                minLength={6}
                                maxLength={10}
                                value={formdata.phoneNumber}
                                onChange={handleProfileChange}
                              ></input>
                              {phoneNumberErr == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {validationnErr.phoneNumber}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                              <label className="my-3">
                                Address
                                <small>4/100characters</small>
                              </label>
                              <input
                                type="text"
                                name="Address"
                                required=""
                                className="pan_number"
                                placeholder="Address"
                                minLength={4}
                                maxLength={100}
                                value={formdata.Address}
                                onChange={handleProfileChange}
                              ></input>
                              {addressErr == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {validationnErr.Address}{" "}
                                </p>
                              ) : (
                                ""
                              )}

                              <div className="col-md-4 justify-content-end ">
                                {buttonLoader == false ? (
                                  <Button
                                    className="changepassword"
                                    onClick={EditProfile}
                                  >
                                    Edit Profile
                                  </Button>
                                ) : (
                                  <Button className="changepassword">
                                    Loading...
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div id="menu2" class="tab-pane fade">
                        <div className="contant_secion_balance mt-4">
                          <div className="row justify-content-center mt-3">
                            <div className="col-lg-6 border rounded shadow p-5">
                              <div className="row mt-2">
                                <div className="col-lg-12">
                                  <h3 className="input_labele">Old password</h3>
                                  <div className="with-eye">
                                    <input
                                      type={inputtype1}
                                      name="oldpassword"
                                      required=""
                                      className="pan_number"
                                      id="exampleInputPassword1"
                                      placeholder="Old password"
                                      value={oldpassword}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      {passhide1 == false ? (
                                        <i
                                          className="bi bi-eye-slash-fill"
                                          onClick={() => oldPassword("hide")}
                                        ></i>
                                      ) : (
                                        <i
                                          className="bi bi-eye-fill"
                                          onClick={() => oldPassword("show")}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    {oldpassvalidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.oldpassword}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <h3 className="input_labele mt-3">New password</h3>
                                  <div className="with-eye">
                                    <input
                                      type={inputtype2}
                                      name="password"
                                      required=""
                                      id="exampleInputPassword1"
                                      placeholder="New password"
                                      className="pan_number"
                                      value={password}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      {passhide2 == false ? (
                                        <i
                                          className="bi bi-eye-slash-fill"
                                          onClick={() => newPassword("hide")}
                                        ></i>
                                      ) : (
                                        <i
                                          className="bi bi-eye-fill"
                                          onClick={() => newPassword("show")}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    {passwordValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.password}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <h3 className="input_labele mt-3">
                                    Confirm password
                                  </h3>
                                  <div className="with-eye">
                                    <input
                                      type={inputtype3}
                                      required=""
                                      id="exampleInputPassword1"
                                      placeholder="confirm password"
                                      className="pan_number"
                                      name="confirmPassword"
                                      value={confirmPassword}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      {passhide3 == false ? (
                                        <i
                                          className="bi bi-eye-slash-fill"
                                          onClick={() => conPassword("hide")}
                                        ></i>
                                      ) : (
                                        <i
                                          className="bi bi-eye-fill"
                                          onClick={() => conPassword("show")}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    {confirmPasswordValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.confirmPassword}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  {buttonLoader == false ? (
                                    <Button
                                      className="changepassword"
                                      onClick={formSubmit}
                                    >
                                      Change Password
                                    </Button>
                                  ) : (
                                    <Button className="changepassword">
                                      Loading...
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
