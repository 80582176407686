import React, { useEffect } from "react";

import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";
import "semantic-ui-css/semantic.min.css";
import { Dropdown } from "semantic-ui-react";
import useState from "react-usestateref";
import Sidebar from "./Sidebarlink";
import { setAuthorization } from "../core/service/axios";
import axios from "axios";

function Home() {


  useEffect(() => {
    getCurrency();
    swaphistory();
  }, [])

  const [isScrolled, setIsScrolled] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [receiveshowDiv, setreceiveShowDiv] = useState(false);


  const initialValues = {
    send: "",
  };

  const initialValues1 = {
    receive: "",
  };

  const [selectedreceiveCurrency, setSelectedreceiveCurrency, selectedreceiveCurrencyref] = useState();
  const [selectedCurrency, setSelectedCurrency, selectedCurrencyref] = useState();
  const [comparecurrency, Setcomparecurrency, comparecurrencyref] = useState();
  const [comparereceivecurrency, Setcomparereceivecurrency, comparereceivecurrencyref] = useState();
  const [value, Setvalue, valueref] = useState(0);
  const [receiveamount, Setreceiveamount, receiveamountref] = useState();
  const [total, Settotal, totalref] = useState();
  const [getCurrencyData, setGetCurrencyData, getCurrencyDataref] = useState([]);
  const [getreceiveCurrencyData, setGetReceiveCurrencyData] = useState([]);
  const [swapHistory, setSwapHistory, swapHistoryref] = useState([]);
  const [totalPage, setTotalpages] = useState(0);

  const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [validationnErr, setvalidationnErr] = useState("");
  const [currency, setCurrency] = useState("");
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [formValue, setFormValue, formValueref] = useState(initialValues);
  const [formValue1, setFormValue1, formValue1ref] = useState(initialValues1);
  const [fees, Setfees, feesref] = useState(0);
  const [vallidation, setVallidation] = useState("");
  const [sendErr, setSendErr, setSendErrref] = useState(false);
  const [receiveErr, setReceiveErr, setReceiveErrref] = useState(false);
  const [inputType, setInputType] = useState("password");
  const { send } = formValue;
  const { receive } = formValue1;


  const handleCurrencySelect = (currency) => {
    if (currency !== selectedCurrency) {
      setSelectedCurrency(currency);
      console.log(selectedCurrencyref.current, "selectedCurrency")
      Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparereceivecurrencyref.current}&tsyms=${comparecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response")
            const currencyPair = comparereceivecurrencyref.current;
            const Value = response.data[currencyPair][comparecurrencyref.current];
            const Total = Value * formValue1ref.current.receive;
            setFormValue({
              send: Total
            });
            data()
            console.log("BTC Value:", Total);
          } else { toast.error("This Currency Cannot Working"); }
        }).catch((error) => {

        });

      handleToggle()
      data()
    }
  };

  const handlereceiveCurrencySelect = (amount) => {
    if (amount !== selectedreceiveCurrency) {
      setSelectedreceiveCurrency(amount);
      console.log(selectedreceiveCurrencyref.current, "selectedreceiveCurrency")
      Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response");
            const currencyPair = comparecurrencyref.current;
            const Value = response.data[currencyPair][comparereceivecurrencyref.current];
            const Total = Value * formValueref.current.send;

            setFormValue1({
              receive: Total
            });
            console.log("BTC Value:", Total);
            data()

          } else {
            toast.error("This Currency Cannot Work");
          }
        })
        .catch((error) => {
        });

      handleToggle1()
      data()
    }
  };

  const handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formdata = { ...formValue, ...{ [name]: value } };
      setFormValue(formdata);
      Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response");
            const currencyPair = comparecurrencyref.current;
            const Value = response.data[currencyPair][comparereceivecurrencyref.current];
            const Total = Value * formValueref.current.send;

            setFormValue1({
              receive: Total
            });
            console.log("BTC Value:", Total);
            data()

          } else {
            toast.error("This Currency Cannot Work");
          }
        })
        .catch((error) => {
        });

    } catch (error) { }
  };


  const handleChange1 = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formdata = { ...formValue1, ...{ [name]: value } };
      setFormValue1(formdata);

      Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)

      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparereceivecurrencyref.current}&tsyms=${comparecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response")
            const currencyPair = comparereceivecurrencyref.current === undefined ? "BTC" : comparereceivecurrencyref.current;
            const Value = response.data[currencyPair][comparecurrencyref.current];
            const Total = Value * formValue1ref.current.receive;
            setFormValue({
              send: Total
            });
            data()
            console.log("BTC Value:", Total);
          } else { toast.error("This Currency Cannot Working"); }
        }).catch((error) => {

        });

    } catch (error) { }
  };

  const data = () => {
    try {
      console.log(comparecurrencyref.current)
      console.log(comparereceivecurrencyref.current)
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response");
            const currencyPair = comparecurrencyref.current;
            const Value = response.data[currencyPair][comparereceivecurrencyref.current];
            Setvalue(Value);
            const selectedBTCAmount = formValueref.current.send;
            var feeRate = 0.001;
            var feeAmount = (+selectedBTCAmount) * feeRate;
            Setfees(feeAmount)
            const totalAmountWithFee = (+selectedBTCAmount) + feeAmount;

            Settotal(totalAmountWithFee)
            console.log("BTC Value:", valueref.current);
          } else {
            toast.error("This Currency Cannot Work");
          }
        })
        .catch((error) => {
        });
    } catch (error) {
      console.log(error)
    }
  }

  const validate = () => {
    try {
      const error = {};
      if (formValue1.receive == "") {
        error.receive = "Enter Amount";
        setReceiveErr(true);
      } else if (!/^[0-9]*(\.[0-9]+)?$/.test(formValue1.receive)) {
        setReceiveErr(true);
        error.receive = "Invalid Number Format";
      } else {
        setReceiveErr(false);
      }

      if (formValue.send == "") {
        error.send = "Enter Amount";
        setSendErr(true);
      } else if (!/^[0-9]*(\.[0-9]+)?$/.test(formValue.send)) {
        setSendErr(true);
        error.send = "Invalid Number Format";
      } else {
        setSendErr(false);
      }

      setVallidation(error);
    } catch (error) {
      console.log(error, "==========");
    }
  };


  const getCurrency = async () => {
    try {
      var data1 = {
        apiUrl: apiService.getCurrency,
      };
      var resp = await getMethod(data1);
      if (resp.status === true) {
        setGetCurrencyData(resp.data);
        setGetReceiveCurrencyData(resp.data);
        setSelectedCurrency(getCurrencyDataref.current[0])
        console.log(selectedCurrencyref.current)
        setSelectedreceiveCurrency(getCurrencyDataref.current[2])
        console.log(selectedreceiveCurrencyref.current)
        Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
        console.log(comparecurrencyref.current)
        Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
        console.log(comparereceivecurrencyref.current)
        data();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };


  const swap = async (e) => {
    e.preventDefault();

    validate(formValue);
    validate(formValue1);


    if (setSendErrref.current == false && setReceiveErrref.current == false && formValueref.current.send != "" && formValue1ref.current.receive != "" && comparereceivecurrencyref.current != "" && comparecurrencyref.current != "") {
      try {
        console.log(selectedreceiveCurrencyref.current)
        var obj = {
          from: selectedCurrencyref.current.currencySymbol,
          to: selectedreceiveCurrencyref.current.currencySymbol,
          from_id: selectedCurrencyref.current._id,
          to_id: selectedreceiveCurrencyref.current._id,
          fromAmount: formValue.send,
          toAmount: formValue1.reveive,
          withFee: totalref.current,
          fee: feesref.current,
          currentPrice: formValue1.receive,
        }
        var data = {
          apiUrl: apiService.swapping,
          payload: obj,
        };

        console.log(obj, "obj")

        var resp = await postMethod(data);
        console.log(resp, "resp==-=-=resp");
        if (resp.status == true) {
          await setAuthorization(resp.token);
          toast.success(resp.Message)

        } else {
          toast.error(resp.Message)
        }
        setFormValue({
          send: 0,
        });
        setFormValue1({
          receive: 0,
        });
        getCurrency()

        setSelectedCurrency(null);
        setSelectedreceiveCurrency(null)
      } catch (error) {
        console.error("Failed to add person", error);
      }
    } else {
      toast.error("Please choose the swapping currencies");

    }
  };

  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 10,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setSwapHistory(resp.data.data);
        console.log(swapHistoryref.current, "swapHistory")
        setTotalpages(resp.data.total);
      }
    } catch (error) { }
  };

  const handleToggle = () => {
    setShowDiv(!showDiv);
  };

  const handleToggle1 = () => {
    setreceiveShowDiv(!receiveshowDiv);
  };
  const swapping = () => {
    var temp = formValue.send;
    var temp1 = formValue1.receive;
    setFormValue({
      send: temp1,
    })
    setFormValue1({
      receive: temp,
    })
    setSelectedCurrency(selectedreceiveCurrencyref.current);
    setSelectedreceiveCurrency(selectedCurrency);
    Setcomparecurrency(selectedCurrencyref.current.currencySymbol)
    Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
    data();

  }

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              <div className="section_selec_ash">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="heading_titlr mt-4">Swap</h2>
                    </div>
                  </div>
                </div>
              </div>

              <>
                <section className=" bg_trrrr w-100">
                  <div className="container">
                    <div className="col-lg-12">
                      <div className="row justify-content-center">
                        <div className="col-lg-8">
                          <div className="swap_card">
                            <div className="form_swap">
                              <div className="input_send">
                                <span>You Send</span>
                                <input type="number" placeholder="" name="send"
                                  value={send}
                                  onChange={handleChange}
                                />

                              </div>
                              <div className="select_drobdoan" onClick={handleToggle}>
                                {selectedCurrency ? (
                                  <div>
                                    <img src={selectedCurrency.Currency_image} width="30%" />
                                    <p>{selectedCurrency.currencySymbol}</p>
                                  </div>
                                ) : (
                                  <div>
                                    <img src="https://static.simpleswap.io/images/currencies-logo/eth.svg" />
                                    <p>ETH</p>
                                  </div>
                                )}
                                <i class="ri-arrow-drop-right-line"></i>
                              </div>
                              <div className={`custom-div dropdown_mnyw  ${showDiv ? 'show' : 'hide'}`}>
                                <div className="header">
                                  <span><i class="ri-search-line"></i></span>
                                  <input type="text" placeholder="Search" />
                                  <button onClick={handleToggle}><i class="ri-close-fill"></i></button>
                                </div>
                                <div className="body_slect">
                                  <p>Popular cryptocurrencies</p>

                                  {getCurrencyData.length > 0 ? (
                                    getCurrencyData.map((dataItem, i) => (
                                      <div className="coin_slect" key={i} onClick={() => handleCurrencySelect(dataItem)}>
                                        <div className="coin_name">
                                          <img src={dataItem.Currency_image} alt="Currency Logo" />
                                          <span>{dataItem.currencySymbol}</span>
                                        </div>
                                        <small>{dataItem.currencyName}</small>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No --Data-- Found</p>
                                  )}


                                </div>
                              </div>
                            </div>
                            {sendErr == true ? (
                              <p className="text-danger m-2"> {vallidation.send} </p>
                            ) : (
                              ""
                            )}
                            <div className="span_button_tranfser">
                              <Button onClick={swapping}><i class="ri-exchange-funds-line" ></i></Button>
                            </div>



                            <div className="form_swap">
                              <div className="input_send">
                                <span>You Recive</span>
                                <input type="number" placeholder=""
                                  name="receive"
                                  value={receive}
                                  onChange={handleChange1}
                                />

                              </div>
                              <div className="select_drobdoan" onClick={handleToggle1}>
                                {selectedreceiveCurrency ? (
                                  <div>
                                    <img src={selectedreceiveCurrency.Currency_image} width="30%" />
                                    <p>{selectedreceiveCurrency.currencySymbol}</p>
                                  </div>
                                ) : (
                                  <div>
                                    <img src="https://static.simpleswap.io/images/currencies-logo/btc.svg" />
                                    <p>BTC</p>
                                  </div>
                                )}
                                <i class="ri-arrow-drop-right-line"></i>
                              </div>
                              <div className={`custom-div dropdown_mnyw  ${receiveshowDiv ? 'show' : 'hide'}`}>
                                <div className="header">
                                  <span><i class="ri-search-line"></i></span>
                                  <input type="text" placeholder="Search" />
                                  <button onClick={handleToggle1}><i class="ri-close-fill"></i></button>
                                </div>
                                <div className="body_slect">
                                  <p>Popular cryptocurrencies</p>

                                  {getreceiveCurrencyData.length > 0 ? (
                                    getreceiveCurrencyData.map((data, i) => (
                                      <div className="coin_slect" key={i} onClick={() => handlereceiveCurrencySelect(data)}>
                                        <div className="coin_name">
                                          <img src={data.Currency_image} alt="Currency Logo" />
                                          <span>{data.currencySymbol}</span>
                                        </div>
                                        <small>{data.currencyName}</small>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No --Data-- Found</p>)}


                                </div>
                              </div>
                            </div>
                            {receiveErr == true ? (
                              <p className="text-danger m-2"> {vallidation.receive} </p>
                            ) : ("")}
                            <div className="text_content">
                              <p>Price<span>1 {comparecurrencyref.current === undefined ? "ETH" : comparecurrencyref.current}  ≈ {valueref.current} {comparereceivecurrencyref.current === undefined ? "BTC" : comparereceivecurrencyref.current}</span></p>
                              <p>Swap fee<span> 0.001</span></p>
                              <p>Total<span> {totalref.current}</span></p>
                            </div>
                            <div className="swap_section">
                              <Button onClick={swap}>Swap Now</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            </main>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default Home;
