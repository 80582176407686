import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { setAuthorization } from "../core/service/axios";

function Home() {
  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { email, password } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.email != "" && formValue.password != "") {
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      localStorage.setItem("socket_token", resp.socketToken);
      if (resp?.tfa === 1) {
        setbuttonLoader(false);
        localStorage.setItem("socket_token", resp.socketToken);

        navigate("/tfalogin", {
          state: {
            socketToken: resp.socketToken,
          },
        });
      } else {
        if (resp.status) {
          setbuttonLoader(false);
          toast.success(resp.Message);
          console.log("resp.token====", resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          setAuthorization(resp.token);
          navigate("/dashboard");
         
          //window.location.href = "https://latoken.com/exchange/ADVB_USDT";
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
      <div className="login_form row">
          <div className="framen_login col-lg-6">
            <div className="contnt_login">
              <h2>Swapo</h2>
              <p>Trade Instantly & Pool Tokens to Earn Rewards</p>
            </div>
          </div>
          <div className="frame_fprm col-lg-6">
          <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card_login login_card_s">
                  <h3>Log In</h3>
                  <p>Not Swapo customer yet? <a href="/register">Sign Up</a></p>
                  <div>
                    <div class="form_login_input">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                      value={email}
                      onChange={handleChange}
                    />
                    {emailValidate == true ? (
                      <p className="text-danger"> {validationnErr.email} </p>
                    ) : (
                      ""
                    )}                  
                    </div>
                    <div class="form_login_input">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        name="password"
                      value={password}
                      onChange={handleChange}
                    />
                    {passwordValidate == true ? (
                      <p className="text-danger">
                        {" "}
                        {validationnErr.password}{" "}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                    <div class="form_login_input">
                      {buttonLoader == false ? (
                        <Button className="mt-4" onClick={formSubmit}>Login</Button>
                      ) : (
                        <Button className="mt-4">Loading...</Button>
                      )}
                    </div>
                    <Link to="/forget" className="pass_forgee mt-3 d-block">
                      Forget Password ?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </div>
          
        </div>
        
      </main>
      <Footer />
    </div>
  );
}

export default Home;
