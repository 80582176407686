import React, { useEffect } from "react";

import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";
import "semantic-ui-css/semantic.min.css";
import { Dropdown } from "semantic-ui-react";
import useState from "react-usestateref";
import Sidebar from "./Sidebarlink";
import { setAuthorization } from "../core/service/axios";
import axios from "axios";

function Home() {


  useEffect(() => {
    swaphistory();
  }, [])

  const [swapHistory, setSwapHistory, swapHistoryref] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    swaphistory(pageNumber);
    setCurrentPage(pageNumber);
  };


  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setSwapHistory(resp.data.data);
        console.log(swapHistoryref.current, "swapHistory")
        setTotalpages(resp.data.total);
      }
    } catch (error) { }
  };
 

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght bg-white">
              

              <>
               

                <section className="tavle_transactio">
                  <div className="padding_section">
                    <div className="container-fluid">
                      <h2 className=" heading_titlr p-3 mt-5">
                        Swap History
                      </h2>
                      <div className="row justify-content-center">
                        <div className="col-lg-10 shadow">
                          <div className="market_tabel1 w-100 mt-0 p-3">
                            <div className="market_section">
                              <div className="table-responsive">
                                <table class="table mt-4">
                                  <thead>
                                    <tr>
                                      <th>S.No</th>
                                      <th>FromCurrency</th>
                                      <th>ToCurrency</th>
                                      <th>Amount</th>
                                      <th>Price</th>
                                      <th>Total Amount</th>
                                      <th>Fees</th>
                                      <th>Date / time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {swapHistoryref.current.length > 0 ? (
                                      swapHistoryref.current &&
                                      swapHistoryref.current.map((item, i) => {
                                        var recv_amt = item.amount * item.price;
                                        return (
                                          <tr>
                                            <td className="text-dark">{i + 1}</td>
                                            <td className="text-dark">{item.fromCurrency}</td>
                                            <td className="text-dark">{item.toCurrency}</td>
                                            <td className="text-dark">{parseFloat(item.amount).toFixed(2)} {""} {item.fromCurrency}</td>
                                            <td className="text-dark">{parseFloat(item.price).toFixed(5)} {""} {item.toCurrency}</td>
                                            <td className="text-dark">{parseFloat(item.totalAmount).toFixed(5)} {""} {item.fromCurrency}</td>
                                            <td className="text-dark">{parseFloat(item.fee).toFixed(5)} {""} {item.fromCurrency}</td>
                                            {/* <td className="text-dark">{parseFloat(recv_amt).toFixed(5)} {""} {item.toCurrency}</td> */}
                                            <td className="text-dark">
                                              {moment(item.createdDate).format("lll")}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan="8">
                                        <span className="w-100 text-center d-block">
                                          No  History Found!
                                        </span>
                                      </td>
                                    )}
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                          {swapHistoryref.current.length > 0 ? (
                      <Pagination
                      className="mx-auto d-block"
                        itemClass="page-item" 
                        linkClass="page-link" 
                        activePage={currentPage}
                        itemsCountPerPage={recordPerPage}
                        totalItemsCount={totalPage}
                        pageRangeDisplayed={pageRange}
                        onChange={handlePageChange}
                      />
                    ) : (
                      ""
                    )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            </main>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default Home;
