import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "./Sidebarlink";

function Home() {
  const friendOptions = [
    {
      key: "BTC",
      text: "BTC",
      value: "BTC",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "ETH",
      text: "ETH",
      value: "ETH",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/elliot.jpg",
      },
    },
  ];

  const navigate = useNavigate();
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);

  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const recordPerPage = 5;
  const pageRange = 5;

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [withAddressValidate, setwithAddress] = useState(false);
  const [tfaValidate, settfaValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [network, setNetwork] = useState("");
  const [kycStatus, setkycStatus] = useState(1);
  const [loader, setloader] = useState(false);

  const { amount, withAddress, tfa, withdraw_otp } = formValue;

  useEffect(() => {
    getKYCstatus();
    getAllcurrency();
    getwithdrawHistory(1);
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const getAllcurrency = async () => {
    setloader(true);
    var data = {
      apiUrl: apiService.withdrawcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      setloader(false);
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          //label: data[i].currencySymbol,
          key: data[i].currencySymbol,
          text: data[i].currencySymbol,
          image: {
            avatar: true,
            src: data[i].Currency_image,
          },
          coinType: data[i].coinType,
          erc20token: data[i].erc20token,
          bep20token: data[i].bep20token,
          trc20token: data[i].trc20token,
          rptc20token: data[i].rptc20token,
        };
        currArrayCrypto.push(obj);
      }
      setallCurrency(currArrayCrypto);
    }
  };

  const onSelect = async (e, option) => {
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setcurrentcurrency(currencydata);
      setcur_network([]);
      setnet_default("");
      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
          };
          network_names.push(network_cur);
        }

        if (currencydata.rptc20token == "1") {
          network_cur = {
            value: "rptc20token",
            label: "RPTC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].label);
        setcurrency(currencydata);
        setcointype(currencydata.coinType);
      }
      setwithdrawType(currencydata.coinType);

      var obj = {
        currency: currencydata.currencySymbol,
        currId: currencydata._id,
      };
      var data = {
        apiUrl: apiService.user_balance,
        payload: obj,
      };

      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview(true);
        setBalance(resp.data);
      } else {
      }
    }
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const validate_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setTotalpages(withdraw_history_list.pages);
    }
  };

  const handlePageChange = (pageNumber) => {
    getwithdrawHistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getUserDetails,
    };
    var getKYC = await getMethod(data);
    if (getKYC.status) {
      console.log(getKYC, "==-=getKYC=-=-=");
      setkycStatus(getKYC.data.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const onSelect_network = async (option) => {
    setnet_current(option.label);
  };

  const withdrawPreview = async () => {
    try {
      console.log(
        "******withdrawPreview*********",
        withdrawType,
        typeof withdrawType
      );
      if (withdrawType == "2") {
        console.log("***************");
        validateFiat_preview(formValue);
        if (formValue.amount != "") {
          if (+formValue.amount > 0) {
            if (+balanceref.current.balance > +formValue.amount) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var data = {
                  apiUrl: apiService.send_otp,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  setbuttonLoader(false);
                  setshow_otp(true);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                }
              }
            } else {
              toast.error("Insufficient Balance!");
              setbuttonLoader(false);
            }
          } else {
            toast.error("Please give valid withdraw amount!");
            setbuttonLoader(false);
          }
        }
      } else {
        validate_preview(formValue);
        if (formValue.amount != "" && formValue.withAddress != "") {
          if (+formValue.amount > 0) {
            if (+balanceref.current.balance > +formValue.amount) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var data = {
                  apiUrl: apiService.send_otp,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  setbuttonLoader(false);
                  setshow_otp(true);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                }
              }
            } else {
              toast.error("Insufficient Balance");
              setbuttonLoader(false);
            }
          } else {
            toast.error("Please give valid withdraw amount!");
          }
        }
      }
    } catch (error) {}
  };

  const withdrawSubmit = async () => {
    try {
      console.log(
        "******withdrawSubmitwithdrawSubmit*********",
        withdrawType,
        typeof withdrawType
      );
      if (withdrawType == "2") {
        console.log("***************");
        validateFiat_submit(formValue);
        if (localStorage.getItem("tfa_status") == 1) {
          if (formValue.amount != "" && formValue.tfa != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  formValue.withAddress = "";
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  formValue.withAddress = "";
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        }
      } else {
        validate_submit(formValue);
        if (localStorage.getItem("tfa_status") == 1) {
          if (
            formValue.amount != "" &&
            formValue.withAddress != "" &&
            formValue.tfa != ""
          ) {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  formValue.withAddress = "";
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  formValue.withAddress = "";
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Referral Link copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const redirect_url = async (url) => {
    navigate(url);
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row ">
                        <div className="col-lg-12">
                          <div className="headin_deposur_s">
                            <h3>
                              {/* <Link to="/dashboard">
                          <i class="ri-arrow-left-fill"></i>
                        </Link> */}
                              Withdraw
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit">
                    <div className="container-fluid">
                      <div className="row justify-content-center m-5">
                        <div className="col-lg-6 border rounded p-3 shadow">
                          {show_otpref.current == false ? (
                            <div className="crypto_coin_selec">
                              <label>Select Coin</label>
                              <Dropdown
                                placeholder="Select Coin"
                                fluid
                                selection
                                options={allCurrencyref.current}
                                onChange={onSelect}
                              />
                            </div>
                          ) : (
                            " "
                          )}{" "}
                          <div className="qr_section_class1">
                            {/* <h4>Withdraw Address</h4> */}
                            {show_otpref.current == false &&
                            currentcurrencyref.current.currencyType == "2" ? (
                              <div className="crypto_coin_selec pa-_opo">
                                <label>Select Network</label>
                                <Select
                                  options={network_currencyref.current}
                                  placeholder="Select an Network"
                                  onChange={(o) => onSelect_network(o)}
                                  defaultValue={net_defaultref.current}
                                  isSearchable={true}
                                  className="call_seclecy"
                                />
                              </div>
                            ) : (
                              ""
                            )}{" "}
                            {withdrawType == "1" &&
                            show_otpref.current == false ? (
                              <div className="crypto_coin_selec mt-3 pa-_opo">
                                <label>Address</label>

                                <div className="flex_coin_copy">
                                  <input
                                    type="text"
                                    placeholder="Enter Address"
                                    name="withAddress"
                                    value={withAddress}
                                    onChange={handleChange}
                                  />
                                  {/* <i class="ri-file-copy-line"></i> */}
                                </div>
                                <div>
                                  {withAddressValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.withAddress}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {show_otpref.current == false ? (
                              <div className="crypto_coin_selec mt-3 pa-_opo">
                                <label>Amount</label>
                                <div className="flex_coin_copy">
                                  <input
                                    type="text"
                                    placeholder="Enter Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {amountValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.amount}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {show_otpref.current == true ? (
                              <>
                                {/* {localStorage.getItem("tfa_status") == 1 ? ( */}
                                {/* <>
                           <div className="crypto_coin_selec pa-_opo">
                            <label>2FA</label>
                              <div className="flex_coin_copy">
                              <input type="text" placeholder="Enter 2FA Code"  name="tfa" value={tfa} onChange={handleChange}/>
                              </div>
                              <div>
                              {tfaValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {
                                  validationnErr.tfa
                                }{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            </div> 
                          </div>
                          </> */}
                                {/* ) : ("")} */}

                                <div className="crypto_coin_selec pa-_opo">
                                  <label>Withdraw OTP</label>
                                  <div className="flex_coin_copy">
                                    <input
                                      type="text"
                                      placeholder="Enter Withdraw OTP"
                                      name="withdraw_otp"
                                      value={withdraw_otp}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div>
                                    {otpValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.withdraw_otp}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          {show_otpref.current == false &&
                          viewref.current == true ? (
                            <div className="minimu_depost">
                              <p className="mb-3">
                                Available Balance{" "}
                                <span>
                                  {balanceref.current.balance}{" "}
                                  {currentcurrencyref.current.currencySymbol}
                                </span>
                              </p>
                              <p className="mb-3">
                                Fees{" "}
                                <span>
                                  {currentcurrencyref.current.withdrawFee} %
                                </span>
                              </p>

                              <p className="mb-3">
                                Minimum withdraw{" "}
                                <span>
                                  {currentcurrencyref.current.minWithdrawLimit}{" "}
                                </span>
                              </p>

                              <p>
                                Maximum withdraw{" "}
                                <span>
                                  {currentcurrencyref.current.maxWithdrawLimit}{" "}
                                </span>
                              </p>

                              {/* {balanceref.current.network != "" ? (
                            <p>
                            Network <span>{balanceref.current.network} </span>
                          </p>
                          ) : ("")} */}
                            </div>
                          ) : (
                            ""
                          )}
                          {
                            // localStorage.getItem("tfa_status") == 1 ? (
                            buttonLoader == false ? (
                              <div class="form_login_input">
                                {show_otpref.current == true ? (
                                  <Button onClick={() => withdrawSubmit()}>
                                    Submit
                                  </Button>
                                ) : (
                                  <Button onClick={() => withdrawPreview()}>
                                    Submit
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div class="form_login_input">
                                <Button>Loading...</Button>
                              </div>
                            )
                          }
                          {/* ) : (<div class="form_login_input"><Button onClick={() => redirect_url("/security")}>Enable 2FA</Button></div>)} */}
                          {/* {buttonLoader == false ? (

                        <div class="form_login_input">
                            {show_otpref.current == true ? (
                             <Button onClick={() => withdrawSubmit()}>Submit</Button>
                            ) : (
                              <Button onClick={() => withdrawPreview()}>Submit</Button>
                            )}
                        </div>
                          
                        ):(<Button>Loading...</Button>)} */}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="tavle_transactio">
                    <div className="padding_section">
                      <div className="container-fluid">
                        <h4 className="heading_class_recent p-3">
                          Recent Withdraw
                        </h4>
                        <div className="row justify-content-center">
                          <div className="col-lg-10 shadow">
                            <div className="market_tabel1 w-100 mt-0 p-3">
                              <div className="market_section">
                                <div className="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>Currency</th>
                                        <th>Date & Time</th>
                                        <th>Amount</th>
                                        <th>Address</th>
                                        <th className="text-right">
                                          Transaction Id
                                        </th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {withdrawHistory &&
                                      withdrawHistory.length > 0 ? (
                                        withdrawHistory.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div className="coin_section">
                                                  <img
                                                    src={item.curr_image}
                                                    className="blackshow"
                                                  />
                                                  <span>{item.curr_name}</span>{" "}
                                                  <small>{item.currency}</small>
                                                </div>
                                              </td>
                                              <td>
                                                <p>
                                                  {Moment(
                                                    item.created_at
                                                  ).format(
                                                    "DD.MM.YYYY HH:mm a"
                                                  )}
                                                </p>
                                              </td>

                                              <td>
                                                <p>
                                                  {parseFloat(
                                                    item.amount
                                                  ).toFixed(8)}
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {item.withdraw_address.substring(
                                                    0,
                                                    15
                                                  )}
                                                  ...
                                                </p>
                                              </td>
                                              <td>
                                                <p className="text-right">
                                                  {item.txn_id}
                                                </p>
                                              </td>
                                              <td>
                                                {item.status == "Completed" ? (
                                                  <p>{item.status}</p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.status !== "Completed" ? (
                                                  <p>{item.status}</p>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan="7">
                                            <p className="text-center">
                                              No records found
                                            </p>
                                          </td>
                                        </tr>
                                      )}
                                      {/* <tr>
                                  <td>
                                    <div className="coin_section">
                                      <img
                                        src={require("../Image/BTC.png")}
                                        className="blackshow"
                                      />
                                      <span>Bitcoin</span> <small>BTC</small>
                                    </div>
                                  </td>
                                  <td>
                                    <p>20-20-2023</p>
                                  </td>
                                  <td>
                                    <p>BNB</p>
                                  </td>
                                  <td>
                                    <p>asdjbe7645876342h3g4u2y345364</p>
                                  </td>
                                  <td>
                                    <p className="text-right"> AD0989765465</p>
                                  </td>
                                </tr> */}
                                      {/* <tr>
                                  <td>
                                    <div className="coin_section">
                                      <img
                                        src={require("../Image/ETH.png")}
                                        className="blackshow"
                                      />
                                      <span>Ethereum</span> <small>ETH</small>
                                    </div>
                                  </td>
                                  <td>
                                    <p>20-20-2023</p>
                                  </td>
                                  <td>
                                    <p>BNB</p>
                                  </td>
                                  <td>
                                    <p>asdjbe7645876342h3g4u2y345364</p>
                                  </td>
                                  <td>
                                    <p className="text-right"> AD0989765465</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="coin_section">
                                      <img
                                        src={require("../Image/AVE.png")}
                                        className="blackshow"
                                      />
                                      <span>Avalanche</span> <small>AVE</small>
                                    </div>
                                  </td>
                                  <td>
                                    <p>20-20-2023</p>
                                  </td>
                                  <td>
                                    <p>BNB</p>
                                  </td>
                                  <td>
                                    <p>asdjbe7645876342h3g4u2y345364</p>
                                  </td>
                                  <td>
                                    <p className="text-right"> AD0989765465</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="coin_section">
                                      <img
                                        src={require("../Image/BTC.png")}
                                        className="blackshow"
                                      />
                                      <span>Bitcoin</span> <small>BTC</small>
                                    </div>
                                  </td>
                                  <td>
                                    <p>20-20-2023</p>
                                  </td>
                                  <td>
                                    <p>BNB</p>
                                  </td>
                                  <td>
                                    <p>asdjbe7645876342h3g4u2y345364</p>
                                  </td>
                                  <td>
                                    <p className="text-right"> AD0989765465</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="coin_section">
                                      <img
                                        src={require("../Image/LITE.png")}
                                        className="blackshow"
                                      />
                                      <span>Litecoin</span> <small>LTC</small>
                                    </div>
                                  </td>
                                  <td>
                                    <p>20-20-2023</p>
                                  </td>
                                  <td>
                                    <p>BNB</p>
                                  </td>
                                  <td>
                                    <p>asdjbe7645876342h3g4u2y345364</p>
                                  </td>
                                  <td>
                                    <p className="text-right"> AD0989765465</p>
                                  </td>
                                </tr> */}
                                    </tbody>
                                  </table>
                                  {withdrawHistory.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item" // add it for bootstrap 4
                                      linkClass="page-link" // add it for bootstrap 4
                                      activePage={currentPage}
                                      itemsCountPerPage={recordPerPage}
                                      totalItemsCount={totalPage}
                                      pageRangeDisplayed={pageRange}
                                      onChange={handlePageChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
