import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Home() {


  const initialFormValue = {
    otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [buttonLoader1, setbuttonLoader1] = useState(false);

  const navigate = useNavigate();
  const { otp } = formValue;

  const formSubmit = async (payload) => {
    // return
    if (formValue.otp != "") {
      // return false

      var obj = {
        emailOtp: otp,
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.emailotpverify,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
      }
    } else {
      toast.error("Enter OTP");
    }
  };

  const resendOTP = async (payload) => {
    var obj = {
      email: localStorage.getItem("useremail"),
    };

    var data = {
      apiUrl: apiService.resendCode,
      payload: obj,
    };
    setbuttonLoader1(true);
    var resp = await postMethod(data);
    setbuttonLoader1(false);
    if (resp.status) {
      toast.success(resp.Message);
    } else {
      toast.error(resp.Message);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form row">
          <div className="framen_login col-lg-6">
            <div className="contnt_login">
              <h2>Swapo</h2>
              <p>Trade Instantly & Pool Tokens to Earn Rewards</p>
            </div>
          </div>
          <div className="frame_fprm col-lg-6">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card_login">
                  <h3>Enter OTP To Activate the account</h3>

                  <div>
                    <div class="form_login_input">
                      <label>Email OTP</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter OTP"
                        name="otp"
                        value={otp}
                        onChange={handleChange}
                      />
                    </div>

                    <div class="form_login_input">
                      {buttonLoader == false ? (
                        <Button
                          onClick={formSubmit}
                        >
                          Continue
                        </Button>
                      ) : (
                        <Button>
                          Loading...
                        </Button>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <p className="bottom_linnk my-3">
                        Did not receive the email? Check your spam filter, or
                      </p>


                    </div>

                    <div class="form_login_input">

                      {buttonLoader1 == false ? (
                        <Button onClick={resendOTP}>Resend Code</Button>
                      ) : (
                        <Button onClick={resendOTP}>Loading...</Button>
                      )}

                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </main>
      <Footer />
    </div>
  );
}

export default Home;
