import React, {useState, useEffect} from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {removeAuthToken} from "../core/lib/localStorage";
import {removeAuthorization} from "../core/service/axios";

const pages = ["Products", "Services", "ABoutUs", "ContactUs"];
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = 240;
  const zIndex1= 2000000;
  const [loginCheck, setloginCheck] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        sx={{
          display: {xs: "block", sm: "block"},
          "& .MuiDrawer-paper": {boxSizing: "border-box", width: drawerWidth, zIndex: zIndex1},
        }}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          
         
          {loginCheck == true ? (
          <>
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
              <Link to="/">
              {" "}
              <img 
              width="10%"
                src={require("../Image/logo_light.png")}
                className="blackshow"
              />
            </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>  
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/dashboard" className="link_tage">
                <i class="ri-dashboard-line"></i>
                  Dashboard
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/kycbusiness" className="link_tage">
                <i class="ri-user-follow-line"></i>
                  KYC
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
         

          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/swap" className="link_tage">
                <i class="ri-exchange-line"></i>
                  Swap
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/deposit" className="link_tage">
                <i class="ri-bank-line"></i>
                  Deposit
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/withdraw" className="link_tage">
                  <i class="ri-align-top"></i>
                  Withdraw
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton> 
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/profile" className="link_tage">
                <i class="ri-user-line"></i>
                  User Profile
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton> 
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/swaphistory" className="link_tage">
                  <i class="ri-history-line"></i>
                  Swap History
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton> 
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/swaphistory" className="link_tage" onClick={logout}>
                  <i class="ri-history-line"></i>
                  Logout
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>   
          </>
            ) : 
            <>

            <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
              <Link to="/">
              <img 
              width="10%"
                src={require("../Image/logo_light.png")}
                className="blackshow"
              />
            </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>  
            
            <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/login" className="link_tage">
                <i class="ri-lock-unlock-line"></i>
                  Login
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>  
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/register" className="link_tage">
                <i class="ri-door-lock-line"></i>
                  Register
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>                
            </>}

               
        </List>
      </Drawer>
      <IconButton
        sx={{color: "black", marginLeft: "auto"}}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="black" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
