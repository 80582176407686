import { React, useState, useEffect, useLayoutEffect } from "react";
import logo from "./logo.svg";
// import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/Login";

import Register from "./pages/Register";
import Forget from "./pages/Forget";
import Dashboard from "./pages/Dashboard";
import Kyc from "./pages/Kyc";
import Editprofile from "./pages/Editprofile";
import Deposit from "./pages/Deposit";
import Withdrew from "./pages/Withdrew";
import Swap from "./pages/Swap";
import Swaphistory from "./pages/Swaphistory";
import Buyhistory from "./pages/buycryptohistory";
import Support from "./pages/support";
import Notification from "./pages/notification";
import Kycbusiness from "./pages/Kycbusiness";
import Profile from "./pages/Merchantprofile";
import Otp from "./pages/Otp";
import Buy from "./pages/buycrypto";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAuthToken } from "../src/core/lib/localStorage";
import IdleTimeOutHandler from "./pages/IdleTimeOutHandler";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./pages/ScrollToTop";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
        />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget/:id" element={<Forget />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/swaphistory" element={<Swaphistory />} />
          <Route path="/buyhistory" element={<Buyhistory />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                {" "}
                <Dashboard />{" "}
              </RequireAuth>
            }
          />
          <Route path="/otp" element={<Otp />} />
          <Route
            path="/Kyc"
            element={
              <RequireAuth>
                <Kyc />
              </RequireAuth>
            }
          />
          <Route
            path="/buy"
            element={
              <RequireAuth>
                <Buy />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          
          <Route
            path="/editprofile"
            element={
              <RequireAuth>
                <Editprofile />
              </RequireAuth>
            }
          />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                <Deposit />
              </RequireAuth>
            }
          />
          <Route
            path="/withdraw"
            element={
              <RequireAuth>
                <Withdrew />
              </RequireAuth>
            }
          />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <Support />
              </RequireAuth>
            }
          />
         
          <Route
            path="/kycbusiness"
            element={
              <RequireAuth>
                <Kycbusiness />
              </RequireAuth>
            }
          />
          <Route path="/notification" element={<Notification />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
