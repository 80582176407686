import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "./Sidebarlink";
function Home() {
  const initialFormValue = {
    subject: "",
    catagory: "",
    message: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [subjectErr, SetsubjectErr, subjectErrref] = useState(false);
  const [catagoryErr, SetcatagoryErr, catagoryErrref] = useState(false);
  const [messageErr, SetmessageErr, messageErrref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [ticketdetails, setticketdetails] = useState([]);
  const [currentpage, Setcurrentpage] = useState(1);
  const [totalpage, Settotalpage] = useState(0);
  const { subject, catagory, message } = formValue;

  useEffect(() => {
    getTicket();
  }, []);
  const navigate = useNavigate();

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const validate = async () => {
    const errors = {};
    if (subject == "") {
      errors.subject = " Subject is required !";
      SetsubjectErr(true);
    } else {
      SetsubjectErr(false);
    }
    if (catagory == "") {
      errors.catagory = "Catagory is required !";
      SetcatagoryErr(true);
    } else {
      SetcatagoryErr(false);
    }
    if (message == "") {
      errors.message = "Message is required !";
      SetmessageErr(true);
    } else {
      SetmessageErr(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const createTicket = async () => {
    validate(formValue);

    if (
      subjectErrref.current == false &&
      catagoryErrref.current == false &&
      messageErrref.current == false
    ) {
      var data = {
        apiUrl: apiService.createTicket,
        payload: formValue,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        toast.success(resp.message);
        getTicket();
      } else {
        toast.error(resp.message);
      }
    } else {
    }
  };

  const recordpage = 5;
  const pagerange = 5;
  const historychanges = (pagenumber) => {
    getTicket(pagenumber);
    Setcurrentpage(pagenumber);
  };
  const getTicket = async (page) => {
    var data = {
      apiUrl: apiService.getTicket,
      payload: {
        FilPerpage: 5,
        FilPage: page,
      },
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      setticketdetails(resp.data.data);
      Settotalpage(resp.data.total);
    } else {
      toast.error(resp.message);
    }
  };

  const deleteTicket = async (item) => {
    var obj = {
      id: item.id,
    };
    var data = {
      apiUrl: apiService.deleteTicket,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      toast.success(resp.message);
      getTicket();
    } else {
      toast.error(resp.message);
    }
  };
  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <div>
                    <section className="deposut_section">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="headin_deposur_s">
                              <h3>Raise Your Supports</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="form-deposit">
                      <div className="container-fluid">
                        <div className="row justify-content-center payment_burron">
                          <div className="col-lg-6 border rounded py-4 shadow">
                            <div className="qr_section_class">
                              <div className="crypto_coin_selec pa-_opo">
                                <label>
                                  Subject
                                  <small>4/25characters</small>
                                </label>
                                <div className="flex_coin_copy">
                                  <input
                                    type="text"
                                    placeholder="Subject"
                                    name="subject"
                                    value={subject}
                                    minLength={4}
                                    maxLength={25}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {subjectErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.subject}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="crypto_coin_selec pa-_opo">
                                <label>
                                  Catagory
                                  <small>4/25characters</small>
                                </label>
                                <div className="flex_coin_copy">
                                  <input
                                    type="text"
                                    placeholder="catagory"
                                    name="catagory"
                                    value={catagory}
                                    minLength={4}
                                    maxLength={25}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {catagoryErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.catagory}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="crypto_coin_selec pa-_opo">
                                <label>
                                  Meassage
                                  <small>3/250characters</small>
                                </label>
                                <div className="flex_coin_copy p-0">
                                  <textarea
                                    placeholder="message"
                                    name="message"
                                    value={message}
                                    minLength={3}
                                    maxLength={250}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                                <div>
                                  {messageErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.message}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            {buttonLoader == false ? (
                              <div class="form_login_input pading_foem">
                                <Button onClick={createTicket}>Submit</Button>
                              </div>
                            ) : (
                              <div class="form_login_input">
                                <Button>Loading...</Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="tavle_transactio">
                      <div className="padding_section">
                        <div className="container-fluid">
                          <div className="row justify-content-center">
                            <div className="col-lg-10 shadow">
                              <div className="market_tabel1 w-100 mt-0 p-3">
                                <div className="market_section">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>S.No</th>
                                          <th> Subject</th>
                                          <th>catagory</th>
                                          <th>message</th>
                                          <th>Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {ticketdetails &&
                                        ticketdetails.length > 0 ? (
                                          ticketdetails.map((item, i) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>{i + 1}</td>
                                                  <td>{item.subject}</td>
                                                  <td>{item.catagory}</td>
                                                  <td>{item.message}</td>
                                                  <td>
                                                    {" "}
                                                    <a
                                                      className="text-red"
                                                      onClick={() => {
                                                        deleteTicket(item);
                                                      }}
                                                    >
                                                      {" "}
                                                      <i class="bi bi-trash-fill"></i>
                                                    </a>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td >
                                              <p className="justify-content-center">
                                                No records found
                                              </p>
                                            </td>{" "}
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                    {ticketdetails.length > 0 ? (
                                      <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={currentpage}
                                        itemsCountPerPage={recordpage}
                                        totalItemsCount={totalpage}
                                        pageRangeDisplayed={pagerange}
                                        onChange={historychanges}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
