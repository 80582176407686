import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    type: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    isTerms: "",
    RefferedBy: "",
  };
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [nameValidate, setnameValidate] = useState(false);
  const [emailValidate, setemailValidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [confirmPasswordValidate, setconfirmPasswordValidate] = useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [refValidation, setRefValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  //const [RefferedBy, setRefferedBy] = useState("");

  const navigate = useNavigate();

  const { type, name, email, isTerms, password, confirmPassword, RefferedBy } =
    formValue;

  useEffect(() => {
    window.scrollTo(0, 0);
    var currURL = window.location.href;
    var refferalId = currURL.split("invite/")[1];
    //setRefferedBy(refferalId);
    formValue.RefferedBy = refferalId;
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const handleTerms = (event) => {
    if (event.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
    setIschecked((current) => !current);
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is a required field";
      setnameValidate(true);
    }

    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    }else if (
      values.password.length < 5 ||
      values.password.length > 21
    ) {
      setpasswordValidate(true);
      errors.password = "Password should not below 5 above 21 letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least One lower character";
    }
    else if (!values.password.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character";
    }
    else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least One upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character";
    }
    else {
      setpasswordValidate(false);
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    }

    if (
      values.password &&
      values.confirmPassword &&
      values.password != values.confirmPassword
    ) {
      errors.confirmPassword = "Password and confirm password does not match";
    }

    if (!Terms) {
      errors.terms = "Terms is a required field";
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setTermsValidation(false);
    }

    // if (!values.RefferedBy) {
    //   errors.RefferedBy = "Referral code is a required field";
    //   setRefValidation(true);
    // }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async (payload) => {
    // return
    validate(formValue);
    formValue["type"] = "Merchant";
    if (RefferedBy == "" || RefferedBy == undefined) {
      formValue["ReferelBy"] = "";
    } else {
      formValue["ReferelBy"] = RefferedBy;
    }
    if (
      formValue.name != "" &&
      formValue.email != "" &&
      formValue.password != "" &&
      formValue.confirmPassword != "" &&
      formValue.confirmPassword === formValue.password &&
      Terms == true
    ) {
      console.log(formValue);
      var data = {
        apiUrl: apiService.signup,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/otp");
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
  <div className="login_form row">
    <div className="framen_login col-lg-6">
      <div className="contnt_login">
        <h2>Swapo</h2>
        <p>Trade Instantly & Pool Tokens to Earn Rewards</p>
      </div>
    </div>
    <div className="frame_fprm col-lg-6">
    <div className="row justify-content-center">
        <div className="col-lg-12 ">
          <div className="card_login">
            <h3>Sign Up</h3>
            
            <p>Already have an account? <a href="/login">Log In</a></p>
            <div>
              <div class="form_login_input">
                <input
                  type="text"
                  class="form-control "
                  placeholder="Name" 
                  name="name"
                value={name}
                onChange={handleChange}
              />
              {nameValidate == true ? (
                <p className="text-danger"> {validationnErr.name} </p>
              ) : (
                ""
              )}
              </div>
              <div class="form_login_input">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  name="email"
                value={email}
                onChange={handleChange}
              />
              {emailValidate == true ? (
                <p className="text-danger">
                  {" "}
                  {validationnErr.email}{" "}
                </p>
              ) : (
                ""
              )}
              </div>
              <div class="form_login_input">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Password" 
                  name="password"
                value={password}
                onChange={handleChange}
              />
              {passwordValidate == true ? (
                <p className="text-danger">
                  {" "}
                  {validationnErr.password}{" "}
                </p>
              ) : (
                ""
              )}
              </div>
              <div class="form_login_input">
                <input
                  type="password"
                  class="form-control"
                  placeholder="ConfirmPassword"
                  name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
              />
              {confirmPasswordValidate == true ? (
                <p className="text-danger">
                  {" "}
                  {validationnErr.confirmPassword}{" "}
                </p>
              ) : (
                ""
              )}
              </div>

              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  onChange={handleTerms}
                  value={isChecked}
                />
                <label class="form-check-label" for="exampleCheck1">
                  I have read and agree to Beleaf's{" "}
                  <a href="">Terms of Service</a> and{" "}
                  <a href="">Privacy Policy</a>.
                </label>

                <div>
                  {termsValidation == true ? (
                    <p style={{ color: "red" }}>
                      {" "}
                      Terms and Conditions is required
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>


               <div class="form_login_input">
                      {buttonLoader == false ? (
                        <Button onClick={formSubmit}>Sign up</Button>
                      ) : (
                        <Button>Loading...</Button>
                      )}
                    </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    
  </div>
 
</main>
      <Footer />
    </div>
  );
}

export default Home;
