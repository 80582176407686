import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import { setAuthorization } from "../core/service/axios";
import BannerUI from "./Banner";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../Home.css"
import { toast } from "react-toastify";
import axios from "axios";


function Home() {


  
 
  useEffect(() => {
    getCurrency();

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
 
  }, [])


  const [loginCheck, setloginCheck] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [receiveshowDiv, setreceiveShowDiv] = useState(false);


  const initialValues = {
    send: "",
  };

  const initialValues1 = {
    receive: "",
  };

  const [selectedreceiveCurrency, setSelectedreceiveCurrency, selectedreceiveCurrencyref] = useState();
  const [selectedCurrency, setSelectedCurrency, selectedCurrencyref] = useState();
  const [comparecurrency, Setcomparecurrency, comparecurrencyref] = useState();
  const [comparereceivecurrency, Setcomparereceivecurrency, comparereceivecurrencyref] = useState();
  const [value, Setvalue, valueref] = useState(0);
  const [receiveamount, Setreceiveamount, receiveamountref] = useState();
  const [total, Settotal, totalref] = useState();
  const [getCurrencyData, setGetCurrencyData, getCurrencyDataref] = useState([]);
  const [getreceiveCurrencyData, setGetReceiveCurrencyData] = useState([]);
  const [swapHistory, setSwapHistory, swapHistoryref] = useState([]);
  const [totalPage, setTotalpages] = useState(0);

  const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [validationnErr, setvalidationnErr] = useState("");
  const [currency, setCurrency] = useState("");
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [formValue, setFormValue, formValueref] = useState(initialValues);
  const [formValue1, setFormValue1, formValue1ref] = useState(initialValues1);
  const [fees, Setfees, feesref] = useState(0);
  const [vallidation, setVallidation] = useState("");
  const [sendErr, setSendErr, setSendErrref] = useState(false);
  const [receiveErr, setReceiveErr, setReceiveErrref] = useState(false);
  const [inputType, setInputType] = useState("password");
  const { send } = formValue;
  const { receive } = formValue1;


  const handleCurrencySelect = (currency) => {
    if (currency !== selectedCurrency) {
      setSelectedCurrency(currency);
      console.log(selectedCurrencyref.current, "selectedCurrency")
      Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparereceivecurrencyref.current}&tsyms=${comparecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response")
            const currencyPair = comparereceivecurrencyref.current;
            const Value = response.data[currencyPair][comparecurrencyref.current];
            const Total = Value * formValue1ref.current.receive;
            setFormValue({
              send: Total
            });
            data()
            console.log("BTC Value:", Total);
          } else { toast.error("This Currency Cannot Working"); }
        }).catch((error) => {

        });

      handleToggle()
      data()
    }
  };

  const handlereceiveCurrencySelect = (amount) => {
    if (amount !== selectedreceiveCurrency) {
      setSelectedreceiveCurrency(amount);
      console.log(selectedreceiveCurrencyref.current, "selectedreceiveCurrency")
      Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response");
            const currencyPair = comparecurrencyref.current;
            const Value = response.data[currencyPair][comparereceivecurrencyref.current];
            const Total = Value * formValueref.current.send;

            setFormValue1({
              receive: Total
            });
            console.log("BTC Value:", Total);
            data()

          } else {
            toast.error("This Currency Cannot Work");
          }
        })
        .catch((error) => {
        });

      handleToggle1()
      data()
    }
  };

  const handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formdata = { ...formValue, ...{ [name]: value } };
      setFormValue(formdata);
      
      Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response");
            const currencyPair = comparecurrencyref.current;
            const Value = response.data[currencyPair][comparereceivecurrencyref.current];
            const Total = Value * formValueref.current.send;

            setFormValue1({
              receive: Total
            });
            console.log("BTC Value:", Total);
            data()

          } else {
            toast.error("This Currency Cannot Work");
          }
        })
        .catch((error) => {
        });

    } catch (error) { }
  };


  const handleChange1 = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formdata = { ...formValue1, ...{ [name]: value } };
      setFormValue1(formdata);

      Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)

      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparereceivecurrencyref.current}&tsyms=${comparecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response")
            const currencyPair = comparereceivecurrencyref.current === undefined ? "BTC" : comparereceivecurrencyref.current;
            const Value = response.data[currencyPair][comparecurrencyref.current];
            const Total = Value * formValue1ref.current.receive;
            setFormValue({
              send: Total
            });
            data()
            console.log("BTC Value:", Total);
          } else { toast.error("This Currency Cannot Working"); }
        }).catch((error) => {

        });

    } catch (error) { }
  };

  const data = () => {
    try {
      console.log(comparecurrencyref.current)
      console.log(comparereceivecurrencyref.current)
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      axios.get(apiUrl)
        .then((response) => {
          if (response) {
            console.log(response.data, "response");
            const currencyPair = comparecurrencyref.current;
            const Value = response.data[currencyPair][comparereceivecurrencyref.current];
            Setvalue(Value);
            const selectedBTCAmount = formValueref.current.send;
            var feeRate = 0.001;
            var feeAmount = (+selectedBTCAmount) * feeRate;
            Setfees(feeAmount)
            const totalAmountWithFee = (+selectedBTCAmount) + feeAmount;

            Settotal(totalAmountWithFee)
            console.log("BTC Value:", valueref.current);
          } else {
            toast.error("This Currency Cannot Work");
          }
        })
        .catch((error) => {
        });
    } catch (error) {
      console.log(error)
    }
  }

  const validate = () => {
    try {
      const error = {};
      if (formValue1.receive == "") {
        error.receive = "Enter Amount";
        setReceiveErr(true);
      } else if (!/^[0-9]*(\.[0-9]+)?$/.test(formValue1.receive)) {
        setReceiveErr(true);
        error.receive = "Invalid Number Format";
      }else if (parseFloat(formValue1.receive) <= 0 || isNaN(parseFloat(formValue1.receive))) {
        setReceiveErr(true);
        error.receive = "Invalid Number Format";
      }
       else {
        setReceiveErr(false);
      }

      if (formValue.send == "") {
        error.send = "Enter Amount";
        setSendErr(true);
      } else if (!/^[0-9]*(\.[0-9]+)?$/.test(formValue.send)) {
        setSendErr(true);
        error.send = "Invalid Number Format";
      }else if (parseFloat(formValue.send) <= 0 || isNaN(parseFloat(formValue.send))) {
        setSendErr(true);
        error.send = "Invalid Number Format";
      }
       else {
        setSendErr(false);
      }

      setVallidation(error);
    } catch (error) {
      console.log(error, "==========");
    }
  };


  const getCurrency = async () => {
    try {
      var data1 = {
        apiUrl: apiService.getCurrency,
      };
      var resp = await getMethod(data1);
      if (resp.status === true) {
        setGetCurrencyData(resp.data);
        setGetReceiveCurrencyData(resp.data);
        setSelectedCurrency(getCurrencyDataref.current[0])
        console.log(selectedCurrencyref.current)
        setSelectedreceiveCurrency(getCurrencyDataref.current[2])
        console.log(selectedreceiveCurrencyref.current)
        Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
        console.log(comparecurrencyref.current)
        Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
        console.log(comparereceivecurrencyref.current)
        data();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };


  const swap = async (e) => {
    e.preventDefault();

    validate(formValue);
    validate(formValue1);


    if (setSendErrref.current == false && setReceiveErrref.current == false && formValueref.current.send != "" && formValue1ref.current.receive != "" && comparereceivecurrencyref.current != "" && comparecurrencyref.current != "") {
      try {
        console.log(selectedreceiveCurrencyref.current)
        var obj = {
          from: selectedCurrencyref.current.currencySymbol,
          to: selectedreceiveCurrencyref.current.currencySymbol,
          from_id: selectedCurrencyref.current._id,
          to_id: selectedreceiveCurrencyref.current._id,
          fromAmount: formValue.send,
          toAmount: formValue1.reveive,
          withFee: totalref.current,
          fee: feesref.current,
          currentPrice: formValue1.receive,
        }
        if (loginCheck != true) {
          toast.error("Login First !");          
        } else {
          var data = {
            apiUrl: apiService.swapping,
            payload: obj,
          };
  
          console.log(obj, "obj")
  
          var resp = await postMethod(data);
          console.log(resp, "resp==-=-=resp");
          if (resp.status == true) {
            await setAuthorization(resp.token);
            toast.success(resp.Message)
  
          } else {
            toast.error(resp.Message)
          }          
        }

        setFormValue({
          send: 0,
        });
        setFormValue1({
          receive: 0,
        });
        getCurrency()

        setSelectedCurrency(null);
        setSelectedreceiveCurrency(null)
      } catch (error) {
        console.log("Failed to add person", error);
      }
    } else {
      console.log("Please choose the swapping currencies");
    }
  };



  const handleToggle = () => {
    setShowDiv(!showDiv);
  };

  const handleToggle1 = () => {
    setreceiveShowDiv(!receiveshowDiv);
  };
  const swapping = () => {
    var temp = formValue.send;
    var temp1 = formValue1.receive;
    setFormValue({
      send: temp1,
    })
    setFormValue1({
      receive: temp,
    })
    setSelectedCurrency(selectedreceiveCurrencyref.current);
    setSelectedreceiveCurrency(selectedCurrency);
    Setcomparecurrency(selectedCurrencyref.current.currencySymbol)
    Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
    data();

  }

  return (
    <>
   
   <div className={isScrolled ? "header scrolled" : "header"}>
      <Header />
      <main>
        <section className="bg_banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contsnt">
                  <h1>
                    Crypto Swap
                  </h1>

                </div>
              </div>
              <div className="col-lg-12">
                      <div className="row justify-content-center">
                        <div className="col-lg-8">
                          <div className="swap_card">
                            <div className="form_swap">
                              <div className="input_send">
                                <span>You Send</span>
                                <input type="number" placeholder="" name="send"
                                  value={send}
                                  onChange={handleChange}
                                />

                              </div>
                              <div className="select_drobdoan" onClick={handleToggle}>
                                {selectedCurrency ? (
                                  <div>
                                    <img src={selectedCurrency.Currency_image} width="30%" />
                                    <p>{selectedCurrency.currencySymbol}</p>
                                  </div>
                                ) : (
                                  <div>
                                    <img src="https://static.simpleswap.io/images/currencies-logo/eth.svg" />
                                    <p>ETH</p>
                                  </div>
                                )}
                                <i class="ri-arrow-drop-right-line"></i>
                              </div>
                              <div className={`custom-div dropdown_mnyw  ${showDiv ? 'show' : 'hide'}`}>
                                <div className="header">
                                  <span><i class="ri-search-line"></i></span>
                                  <input type="text" placeholder="Search" />
                                  <button onClick={handleToggle}><i class="ri-close-fill"></i></button>
                                </div>
                                <div className="body_slect">
                                  <p>Popular cryptocurrencies</p>

                                  {getCurrencyData.length > 0 ? (
                                    getCurrencyData.map((dataItem, i) => (
                                      <div className="coin_slect" key={i} onClick={() => handleCurrencySelect(dataItem)}>
                                        <div className="coin_name">
                                          <img src={dataItem.Currency_image} alt="Currency Logo" />
                                          <span>{dataItem.currencySymbol}</span>
                                        </div>
                                        <small>{dataItem.currencyName}</small>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No --Data-- Found</p>
                                  )}


                                </div>
                              </div>
                            </div>
                            {sendErr == true ? (
                              <p className="text-danger m-2"> {vallidation.send} </p>
                            ) : (
                              ""
                            )}
                            <div className="span_button_tranfser">
                              <Button onClick={swapping}><i class="ri-exchange-funds-line" ></i></Button>
                            </div>



                            <div className="form_swap">
                              <div className="input_send">
                                <span>You Recive</span>
                                <input type="number" placeholder=""
                                  name="receive"
                                  value={receive}
                                  onChange={handleChange1}
                                />

                              </div>
                              <div className="select_drobdoan" onClick={handleToggle1}>
                                {selectedreceiveCurrency ? (
                                  <div>
                                    <img src={selectedreceiveCurrency.Currency_image} width="30%" />
                                    <p>{selectedreceiveCurrency.currencySymbol}</p>
                                  </div>
                                ) : (
                                  <div>
                                    <img src="https://static.simpleswap.io/images/currencies-logo/btc.svg" />
                                    <p>BTC</p>
                                  </div>
                                )}
                                <i class="ri-arrow-drop-right-line"></i>
                              </div>
                              <div className={`custom-div dropdown_mnyw  ${receiveshowDiv ? 'show' : 'hide'}`}>
                                <div className="header">
                                  <span><i class="ri-search-line"></i></span>
                                  <input type="text" placeholder="Search" />
                                  <button onClick={handleToggle1}><i class="ri-close-fill"></i></button>
                                </div>
                                <div className="body_slect">
                                  <p>Popular cryptocurrencies</p>

                                  {getreceiveCurrencyData.length > 0 ? (
                                    getreceiveCurrencyData.map((data, i) => (
                                      <div className="coin_slect" key={i} onClick={() => handlereceiveCurrencySelect(data)}>
                                        <div className="coin_name">
                                          <img src={data.Currency_image} alt="Currency Logo" />
                                          <span>{data.currencySymbol}</span>
                                        </div>
                                        <small>{data.currencyName}</small>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No --Data-- Found</p>)}


                                </div>
                              </div>
                            </div>
                            {receiveErr == true ? (
                              <p className="text-danger m-2"> {vallidation.receive} </p>
                            ) : ("")}
                            <div className="text_content">
                              <p>Price<span>1 {comparecurrencyref.current === undefined ? "ETH" : comparecurrencyref.current}  ≈ {valueref.current} {comparereceivecurrencyref.current === undefined ? "BTC" : comparereceivecurrencyref.current}</span></p>
                              <p>Swap fee<span> 0.001</span></p>
                              <p>Total<span> {totalref.current}</span></p>
                            </div>
                            <div className="swap_section">
                              <Button onClick={swap}>Swap Now</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </section>
        {/* <section className="section_style">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="titte_section">
                  <small>Trending</small>
                  <h2>
                    Most Popular <span>Projects</span>
                  </h2>
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Cum, quod?
                  </p>
                </div>
              </div>
            </div>
            <div className="project_card_section">
              <div className="row">
                <div className="col-lg-4">
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_style">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="titte_section">
                  <small>Scale</small>
                  <h2>
                    For Global
                    <span> Adoption</span>
                  </h2>
                  <p>We always provide our best quality for users</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="secure_feture">
                  <img
                    src={require("../Image/scalable.png")}
                    className="img-fluid"
                  />
                  <h4>Scalability </h4>
                  <p>
                    Dedicated blockchains, scalable consensus algorithms, custom
                    Wasm execution environments{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="secure_feture">
                  <img
                    src={require("../Image/encrypted.png")}
                    className="img-fluid"
                  />
                  <h4>Security </h4>
                  <p>
                    Modular ''security as a service'', provided either by
                    Ethereum or by a pool of professional validators
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="secure_feture">
                  <img
                    src={require("../Image/coding.png")}
                    className="img-fluid"
                  />
                  <h4>Developer Experience </h4>
                  <p>
                    Equivalent to Ethereum, no protocol level knowledge
                    required, no token deposits, fees or permissions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_style">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="titte_section">
                  <small>ICO Project</small>
                  <h2>
                    Upcoming <span>ICO</span>
                  </h2>
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Cum, quod?
                  </p>
                </div>
              </div>
            </div>
            <div className="project_card_section">
              <div className="scroll">
                <Slider {...settings}>
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                  <div className="card_projece">
                    <div className="project_imag">
                      <img
                        src="https://thetork.com/demos/html/torkgo/assets/images/igo/item/02.jpg"
                        className="img-fluid"
                      />
                      <div className="network_icon">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="project_name">
                      <div className="project_logo">
                        <img
                          src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                          className="img-fluid"
                        />
                      </div>
                      <h3>DocR Chain</h3>
                    </div>
                    <div className="project_detiles">
                      <p>
                        Round Name: <span>Public</span>
                      </p>
                      <p>
                        Participent: <span>42</span>
                      </p>

                      <p>
                        Project Start: <span>TBA</span>
                      </p>
                    </div>
                    <div className="project_progress">
                      <p>Raised Ammount</p>
                      <h4>
                        5000/<span>15000 BUSD</span>
                      </h4>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </div>
                    <div className="button_sectino">
                      <Button>View Details</Button>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <section className="section_style">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="titte_section">
                  <small>Previous</small>
                  <h2>
                    Completed <span> Project</span>
                  </h2>
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Cum, quod?
                  </p>
                </div>
              </div>
            </div>
            <div className="project_card_section">
              <div className="row">
                <div className="col-lg-12">
                  <div className="completed_project">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="tokandetils">
                          <div className="project_name">
                            <div className="project_logo">
                              <img
                                src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <h3>DocR Chain</h3>
                              <p>PRICE (GAC) = 0.59 BUSD</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <div className="network_icon_deiv">
                          <div className="network_icon">
                            <img
                              src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center justify-content-center">
                        <div className="exact_date">
                          <p>2 Days Ago</p>
                        </div>
                      </div>
                      <div className="col-lg-4 d-flex align-items-center">
                        <div className="project_progress">
                          <p>Raised Ammount</p>
                          <h4>
                            5000/<span>15000 BUSD</span>
                          </h4>
                          <div class="progress">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span class="sr-only">70% Complete</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="completed_project">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="tokandetils">
                          <div className="project_name">
                            <div className="project_logo">
                              <img
                                src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <h3>DocR Chain</h3>
                              <p>PRICE (GAC) = 0.59 BUSD</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <div className="network_icon_deiv">
                          <div className="network_icon">
                            <img
                              src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center justify-content-center">
                        <div className="exact_date">
                          <p>2 Days Ago</p>
                        </div>
                      </div>
                      <div className="col-lg-4 d-flex align-items-center">
                        <div className="project_progress">
                          <p>Raised Ammount</p>
                          <h4>
                            5000/<span>15000 BUSD</span>
                          </h4>
                          <div class="progress">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span class="sr-only">70% Complete</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="completed_project">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="tokandetils">
                          <div className="project_name">
                            <div className="project_logo">
                              <img
                                src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <h3>DocR Chain</h3>
                              <p>PRICE (GAC) = 0.59 BUSD</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <div className="network_icon_deiv">
                          <div className="network_icon">
                            <img
                              src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center justify-content-center">
                        <div className="exact_date">
                          <p>2 Days Ago</p>
                        </div>
                      </div>
                      <div className="col-lg-4 d-flex align-items-center">
                        <div className="project_progress">
                          <p>Raised Ammount</p>
                          <h4>
                            5000/<span>15000 BUSD</span>
                          </h4>
                          <div class="progress">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span class="sr-only">70% Complete</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="completed_project">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="tokandetils">
                          <div className="project_name">
                            <div className="project_logo">
                              <img
                                src="https://thetork.com/demos/html/torkgo/assets/images/igo/author/6.png"
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <h3>DocR Chain</h3>
                              <p>PRICE (GAC) = 0.59 BUSD</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center">
                        <div className="network_icon_deiv">
                          <div className="network_icon">
                            <img
                              src="https://thetork.com/demos/html/torkgo/assets/images/chain/solana.png"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center justify-content-center">
                        <div className="exact_date">
                          <p>2 Days Ago</p>
                        </div>
                      </div>
                      <div className="col-lg-4 d-flex align-items-center">
                        <div className="project_progress">
                          <p>Raised Ammount</p>
                          <h4>
                            5000/<span>15000 BUSD</span>
                          </h4>
                          <div class="progress">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span class="sr-only">70% Complete</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="colo_white section_style colo_section"
          id="section-4"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-center align-items-center">
                <div className="content_white_first">
                  <h3>
                    <span>Join</span> the fastest growing ecosystem
                  </h3>
                  <p>
                    Solana is the fastest blockchain in the world and the
                    fastest growing ecosystem in crypto, with thousands of
                    projects spanning DeFi, NFTs, Web3 and more.
                  </p>
                  <Button>Explore Now</Button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="vedio_secion">
                  <video className="videoTag" autoPlay loop muted>
                    <source src={Bgvedio} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <section className="section_style" id="section-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="applay_sectoion mt-5">
                    <h2>
                      <span>Have Any Project?</span> <br />
                      Launch On LaunchCoinz
                    </h2>
                    <Button>Apply Now</Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section> */}
      </main>
      <div></div>
      <Footer />
    </div>
    </>
  );
}

export default Home;
