import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setAuthorization } from "../core/service/axios";


export default function Buy() {
    const navigate = useNavigate();

    useEffect(() => {
        getCurrency();
        let userToken = localStorage.getItem("user_token");
        if (userToken) {
            setloginCheck(true);
        } else {
            setloginCheck(false);
        }
        setRupee(Fiatcurrency)
    }, [])

    const Fiatcurrency = [{
        Currency_image: "https://e7.pngegg.com/pngimages/756/548/png-clipart-indian-rupee-sign-currency-symbol-computer-icons-rupee-text-trademark-thumbnail.png",
        currencySymbol: "INR",
        currencyName: "INR"
    },
    {
        Currency_image: "https://static.vecteezy.com/system/resources/previews/004/362/246/original/dollar-flat-design-long-shadow-icon-us-dollar-currency-sign-silhouette-symbol-vector.jpg",
        currencySymbol: "USD",
        currencyName: "USD"
    }]
    const [loginCheck, setloginCheck] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showDiv, setShowDiv] = useState(false);
    const [receiveshowDiv, setreceiveShowDiv] = useState(false);
    const initialValues = {
        send: "",
    };

    const initialValues1 = {
        receive: "",
    };
    const [Rupee, setRupee, Rupeeref] = useState([]);
    const [selectedreceiveCurrency, setSelectedreceiveCurrency, selectedreceiveCurrencyref] = useState();
    const [selectedCurrency, setSelectedCurrency, selectedCurrencyref] = useState();
    const [comparecurrency, Setcomparecurrency, comparecurrencyref] = useState();
    const [comparereceivecurrency, Setcomparereceivecurrency, comparereceivecurrencyref] = useState();
    const [value, Setvalue, valueref] = useState(0);
    const [receiveamount, Setreceiveamount, receiveamountref] = useState();
    const [total, Settotal, totalref] = useState();
    const [getCurrencyData, setGetCurrencyData, getCurrencyDataref] = useState([]);
    const [getreceiveCurrencyData, setGetReceiveCurrencyData] = useState([]);
    const [swapHistory, setSwapHistory, swapHistoryref] = useState([]);
    const [totalPage, setTotalpages] = useState(0);

    const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
    const [allCrypto, setallCrypto, allCryptoref] = useState([]);
    const [validationnErr, setvalidationnErr] = useState("");
    const [currency, setCurrency] = useState("");
    const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
    const [formValue, setFormValue, formValueref] = useState(initialValues);
    const [formValue1, setFormValue1, formValue1ref] = useState(initialValues1);
    const [fees, Setfees, feesref] = useState(0);
    const [vallidation, setVallidation] = useState("");
    const [sendErr, setSendErr, setSendErrref] = useState(false);
    const [receiveErr, setReceiveErr, setReceiveErrref] = useState(false);
    const [inputType, setInputType] = useState("password");
    const { send } = formValue;
    const { receive } = formValue1;


    const handleCurrencySelect = (currency) => {
        if (currency !== selectedCurrency) {
            setSelectedCurrency(currency);
            console.log(selectedCurrencyref.current, "selectedCurrency")
            Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
            const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparereceivecurrencyref.current}&tsyms=${comparecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
            axios.get(apiUrl)
                .then((response) => {
                    if (response) {
                        console.log(response.data, "response")
                        const currencyPair = comparereceivecurrencyref.current;
                        const Value = response.data[currencyPair][comparecurrencyref.current];
                        const Total = Value * formValue1ref.current.receive;
                        setFormValue({
                            send: Total
                        });
                        data()
                        console.log("BTC Value:", Total);
                    } else { toast.error("This Currency Cannot Working"); }
                }).catch((error) => {

                });

            handleToggle()
            data()
        }
    };

    const handlereceiveCurrencySelect = (amount) => {
        if (amount !== selectedreceiveCurrency) {
            setSelectedreceiveCurrency(amount);
            console.log(selectedreceiveCurrencyref.current, "selectedreceiveCurrency")
            Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
            const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
            axios.get(apiUrl)
                .then((response) => {
                    if (response) {
                        console.log(response.data, "response");
                        const currencyPair = comparecurrencyref.current;
                        const Value = response.data[currencyPair][comparereceivecurrencyref.current];
                        const Total = Value * formValueref.current.send;

                        setFormValue1({
                            receive: Total.toFixed(8)
                        });
                        console.log("BTC Value:", Total);
                        data()

                    } else {
                        toast.error("This Currency Cannot Work");
                    }
                })
                .catch((error) => {
                });

            handleToggle1()
            data()
        }
    };

    const handleChange = (e) => {
        try {
            e.preventDefault();
            const { name, value } = e.target;
            let formdata = { ...formValue, ...{ [name]: value } };
            setFormValue(formdata);

            Setcomparecurrency(selectedCurrencyref.current.currencySymbol);
            const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
            axios.get(apiUrl)
                .then((response) => {
                    if (response) {
                        console.log(response.data, "response");
                        const currencyPair = comparecurrencyref.current;
                        const Value = response.data[currencyPair][comparereceivecurrencyref.current];
                        const Total = Value * formValueref.current.send;

                        setFormValue1({
                            receive: Total.toFixed(8)
                        });
                        console.log("BTC Value:", Total);
                        data()

                    } else {
                        toast.error("This Currency Cannot Work");
                    }
                })
                .catch((error) => {
                });
        } catch (error) { }
    };


    const handleChange1 = (e) => {
        try {
            e.preventDefault();
            const { name, value } = e.target;
            let formdata = { ...formValue1, ...{ [name]: value } };
            setFormValue1(formdata);

            Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)

            const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparereceivecurrencyref.current}&tsyms=${comparecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
            axios.get(apiUrl)
                .then((response) => {
                    if (response) {
                        console.log(response.data, "response")
                        const currencyPair = comparereceivecurrencyref.current === undefined ? "BTC" : comparereceivecurrencyref.current;
                        const Value = response.data[currencyPair][comparecurrencyref.current];
                        const Total = Value * formValue1ref.current.receive;
                        setFormValue({
                            send: Total
                        });
                        data()
                        console.log("BTC Value:", Total);
                    } else { toast.error("This Currency Cannot Working"); }
                }).catch((error) => {

                });
        } catch (error) { }
    };

    const data = () => {
        try {
            console.log(comparecurrencyref.current)
            console.log(comparereceivecurrencyref.current)
            const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${comparecurrencyref.current}&tsyms=${comparereceivecurrencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
            axios.get(apiUrl)
                .then((response) => {
                    if (response) {
                        console.log(response.data, "response");
                        const currencyPair = comparecurrencyref.current;
                        const Value = response.data[currencyPair][comparereceivecurrencyref.current];
                        Setvalue(Value);
                        const selectedBTCAmount = formValueref.current.send;
                        var feeRate = 0.001;
                        var feeAmount = (+selectedBTCAmount) * feeRate;
                        Setfees(feeAmount)
                        const totalAmountWithFee = (+selectedBTCAmount) + feeAmount;

                        Settotal(totalAmountWithFee)
                        console.log("BTC Value:", valueref.current);
                    } else {
                        toast.error("This Currency Cannot Work");
                    }
                })
                .catch((error) => {
                });
        } catch (error) {
            console.log(error)
        }
    }

    const validate = () => {
        try {
            const error = {};
            if (formValue1.receive == "") {
                error.receive = "Enter Amount";
                setReceiveErr(true);
            } else if (!/^[0-9]*(\.[0-9]+)?$/.test(formValue1.receive)) {
                setReceiveErr(true);
                error.receive = "Invalid Number Format";
            } else if (parseFloat(formValue1.receive) <= 0 || isNaN(parseFloat(formValue1.receive))) {
                setReceiveErr(true);
                error.receive = "Invalid Number Format";
            }
            else {
                setReceiveErr(false);
            }

            if (formValue.send == "") {
                error.send = "Enter Amount";
                setSendErr(true);
            } else if (!/^[0-9]*(\.[0-9]+)?$/.test(formValue.send)) {
                setSendErr(true);
                error.send = "Invalid Number Format";
            } else if (parseFloat(formValue.send) <= 0 || isNaN(parseFloat(formValue.send))) {
                setSendErr(true);
                error.send = "Invalid Number Format";
            }
            else {
                setSendErr(false);
            }

            setVallidation(error);
        } catch (error) {
            console.log(error, "==========");
        }
    };
    const getCurrency = async () => {
        try {
            var data1 = {
                apiUrl: apiService.getCurrency,
            };
            var resp = await getMethod(data1);
            if (resp.status === true) {
                setGetCurrencyData(resp.data);
                setGetReceiveCurrencyData(resp.data);
                setSelectedCurrency(Rupeeref.current[0])
                console.log(selectedCurrencyref.current)
                setSelectedreceiveCurrency(getCurrencyDataref.current[2])
                console.log(selectedreceiveCurrencyref.current)
                Setcomparecurrency("INR");
                console.log(comparecurrencyref.current)
                Setcomparereceivecurrency(selectedreceiveCurrencyref.current.currencySymbol)
                console.log(comparereceivecurrencyref.current)
                data();
            } else {
            }
        } catch (error) {
            console.log(error);
        }
    };

    const swap = async (e) => {
        console.log(formValue1ref.current.receive, "formValue1.reveive")
        try {
            var ToAmount = formValue1.receive - (formValue1.receive / 100);
            console.log(ToAmount, "ToAmount")
            var obj = {
                from: selectedCurrencyref.current.currencySymbol,
                to: selectedreceiveCurrencyref.current.currencySymbol,
                to_id: selectedreceiveCurrencyref.current._id,
                from_id : selectedreceiveCurrencyref.current._id,
                fromAmount: formValue.send,
                toAmount: ToAmount,
                withFee: formValue1.receive,
                Type: "Buy",
                Fee : (formValue1.receive - ToAmount),
            }
            console.log(obj,"obj");
            var data = {
                apiUrl: apiService.Buycrypto,
                payload: obj,
            };
            console.log(obj, "obj");
            var resp = await postMethod(data);
            setFormValue({
                send: 0,
              });
              setFormValue1({
                receive: 0,
              });
            navigate("/buyhistory");
            getCurrency()

        } catch (error) {
            console.log("Failed to add person", error);
        }
    };
    const handleToggle = () => {
        setShowDiv(!showDiv);
    };

    const handleToggle1 = () => {
        setreceiveShowDiv(!receiveshowDiv);
    };

    const Razorbtn = () => {
        const totalAmount = formValue.send * 100;
        const Cur = selectedCurrencyref.current.currencySymbol;
        openRazorpay(totalAmount, Cur);
    };

    const openRazorpay = async (amount, currency) => {
        validate(formValue);
        validate(formValue1);
        if (setSendErrref.current == false && setReceiveErrref.current == false && formValueref.current.send != "" && formValue1ref.current.receive != "" && comparereceivecurrencyref.current != "" && comparecurrencyref.current != "") {
            try {
                const orderResponse = await axios.post("http://localhost:3033/users/orders", { amount, currency });
                const orderData = orderResponse.data.data;
                console.log(orderResponse.data.status,"orderResponse");
                if (orderResponse.data.status == false) {
                    setFormValue({
                        send: 0,
                      });
                      setFormValue1({
                        receive: 0,
                      });
                    toast.error(orderResponse.data.Message)  
                    console.log("hello")                  
                } else {
                    const options = {
                        key: "rzp_test_nD85IS9ULjMJFP",
                        amount: amount * 100, // Amount in paise
                        currency: currency,
                        name: "Swapo",
                        description: "Credits Purchase",
                        order_id: orderData.id,
                        handler: async (response) => {
                            console.log(orderData, "orderData");
                            try {
                                const verifyUrl = "http://localhost:3033/users/verify";
                                const verifyResponse = await axios.post(verifyUrl, {
                                    razorpay_order_id: response.razorpay_order_id,
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    razorpay_signature: response.razorpay_signature,
                                    amount,
                                    currency,
                                });

                                setFormValue({
                                    send: 0,
                                  });
                                  setFormValue1({
                                    receive: 0,
                                  });
    
                                if (verifyResponse.data.status === true) {
                                    toast.success("Payment successful!");
                                    swap();
                                } else {
                                    toast.error("Payment verification failed.");
                                }
                            } catch (error) {
                                console.log(error);
                                toast.error("Payment verification failed.");
                            }
                        },
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.open();                    
                }               
            } catch (error) {
                console.log(error);
                toast.error("Something went wrong while processing payment.");
            }
        } else {
            console.log("Please choose the currencies");
        }
    };


    return (
        <div className="swap_card buycry">
            <div className="form_swap">
                <div className="input_send">
                    <span>Fiat Amount</span>
                    <input type="number" placeholder="" name="send"
                        value={send}
                        onChange={handleChange}
                    />

                </div>
                <div className="select_drobdoan" onClick={handleToggle}>
                    {selectedCurrency ? (
                        <div>
                            <img src={selectedCurrency.Currency_image} width="30%" />
                            <p>{selectedCurrency.currencySymbol}</p>
                        </div>
                    ) : (
                        <div>
                            <img src="https://e7.pngegg.com/pngimages/756/548/png-clipart-indian-rupee-sign-currency-symbol-computer-icons-rupee-text-trademark-thumbnail.png" width="35%" />
                            <p>INR</p>
                        </div>
                    )}

                    <i class="ri-arrow-drop-right-line"></i>
                </div>
                <div className={`custom-div dropdown_mnyw  ${showDiv ? 'show' : 'hide'}`}>
                    <div className="header">
                        <h3 className="mt-2" >Popular Fiatcurrencies</h3>
                        <button onClick={handleToggle}><i class="ri-close-fill"></i></button>
                    </div>
                    <div className="body_slect">
                        <p></p>

                        {Rupee.length > 0 ? (
                            Rupee.map((dataItem, i) => (
                                <div className="coin_slect" key={i} onClick={() => handleCurrencySelect(dataItem)}>
                                    <div className="coin_name">
                                        <img src={dataItem.Currency_image} alt="Currency Logo" />
                                        <span>{dataItem.currencySymbol}</span>
                                    </div>
                                    <small>{dataItem.currencyName}</small>
                                </div>
                            ))
                        ) : (
                            <p>No --Data-- Found</p>
                        )}


                    </div>
                </div>
            </div>
            {sendErr == true ? (
                <p className="text-danger m-2"> {vallidation.send} </p>
            ) : (
                ""
            )}
            <div className="span_button_tranfser">
            </div>



            <div className="form_swap">
                <div className="input_send">
                    <span>Coin Amount</span>
                    <input type="number" placeholder=""
                        name="receive"
                        value={receive}
                        onChange={handleChange1}
                    />

                </div>
                <div className="select_drobdoan" onClick={handleToggle1}>
                    {selectedreceiveCurrency ? (
                        <div>
                            <img src={selectedreceiveCurrency.Currency_image} width="35%" />
                            <p>{selectedreceiveCurrency.currencySymbol}</p>
                        </div>
                    ) : (
                        <div>
                            <img src="https://static.simpleswap.io/images/currencies-logo/btc.svg" />
                            <p>BTC</p>
                        </div>
                    )}
                    <i class="ri-arrow-drop-right-line"></i>
                </div>
                <div className={`custom-div dropdown_mnyw  ${receiveshowDiv ? 'show' : 'hide'}`}>
                    <div className="header">
                        <h3 className="mt-2">Popular cryptocurrencies</h3>
                        <button onClick={handleToggle1}><i class="ri-close-fill"></i></button>
                    </div>
                    <div className="body_slect">

                        {getreceiveCurrencyData.length > 0 ? (
                            getreceiveCurrencyData.map((data, i) => (
                                <div className="coin_slect" key={i} onClick={() => handlereceiveCurrencySelect(data)}>
                                    <div className="coin_name">
                                        <img src={data.Currency_image} alt="Currency Logo" />
                                        <span>{data.currencySymbol}</span>
                                    </div>
                                    <small>{data.currencyName}</small>
                                </div>
                            ))
                        ) : (
                            <p>No --Data-- Found</p>)}
                    </div>
                </div>
            </div>
            {receiveErr == true ? (
                <p className="text-danger m-2"> {vallidation.receive} </p>
            ) : ("")}
            <div className="text_content">
                <p>Price<span>1 {comparecurrencyref.current === undefined ? "INR" : comparecurrencyref.current}  ≈ {valueref.current.toFixed(8)} {comparereceivecurrencyref.current === undefined ? "BTC" : comparereceivecurrencyref.current}</span></p>
                <p>Estimated Receive Amount<span> {(formValue1.receive - (formValue1.receive / 100)).toFixed(8)}  </span></p>
            </div>
            <div className="swap_section">
                <Button onClick={Razorbtn}>Buy</Button>
            </div>
        </div>
    )
}
