import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";
import { Link, useNavigate } from "react-router-dom";
import { removeAuthToken } from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";
import { socket } from "../core/context/socket";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import Moment from "moment";
import { toast } from "react-toastify";

const Header = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  // console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(isMatch);
  const [loginCheck, setloginCheck] = useState(false);
  const [notifications, setNotification] = React.useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      socket.connect();
      let socketToken = token_socket.split("_")[0];
      socket.on("socketResponse" + socketToken, function (res) {
        console.log("notify response====", res);
        if (res.Reason == "notify") {
          getNotifications();
        }

        if (res.Message == "account_deactivate") {
          toast.error("Your account deactivated by admin");
          logout();
        }
      });
    }

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      checkUser();
      setloginCheck(true);
      getNotifications();
    } else {
      setloginCheck(false);
    }
  }, [0]);

  const logout = async () => {
    //await removeAuthorization();
    await removeAuthToken();
    // localStorage.clear();
    // navigate("/");
    // window.location.reload(true);
  };

  const getNotifications = async () => {
    try {
      var data = {
        apiUrl: apiService.notifications,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
      } else {
      }
    } catch (error) {}
  };

  const checkUser = async () => {
    try {
      var data = {
        apiUrl: apiService.usercheck,
      };
      var resp = await getMethod(data);
      if (resp.status) {
      } else {
        console.log("call logout");
        logout();
      }
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <AppBar sx={{ background: "#000000" }} className="header_padidng">
        <Toolbar>
          <div className="imag">
            <Link className="nav_brand" to="/">
              {" "}
              <img
                src={require("../Image/logo_light.png")}
                className="blackshow"
              />
            </Link>
          </div>
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <div className="nav_link_section mr-auto navbar navbar-expand-lg">
                <ul class="navbar-nav ">
                 
                  {/* <li class="nav-item dropdown mega-menu">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="megaMenuDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Earning
                    </a>
                    <div
                      class="dropdown-menu mega-menu-content"
                      aria-labelledby="megaMenuDropdown"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <div className="menu_section">
                            <Link to="">
                              <div className="icon">
                                <i class="ri-rocket-fill"></i>
                              </div>
                              <div className="content">
                                <div className="soon_text">
                                  <h5>Launchpad </h5>
                                  <span className="before">Soon</span>
                                </div>
                                <p>Launch your token on Beleaf</p>
                              </div>
                            </Link>
                            {loginCheck == false ? (
                              <Link to="/login">
                                <div className="icon">
                                  <i class="ri-plant-fill"></i>
                                </div>
                                <div className="content">
                                  <h5>Staking</h5>
                                  <p>stake your crypto asset</p>
                                </div>
                              </Link>
                            ) : (
                              <Link to="/staking">
                                <div className="icon">
                                  <i class="ri-plant-fill"></i>
                                </div>
                                <div className="content">
                                  <h5>Staking</h5>
                                  <p>stake your crypto asset</p>
                                </div>
                              </Link>
                            )}
                            
                            <Link to="">
                              <div className="icon">
                                <i class="ri-nft-fill"></i>
                              </div>
                              <div className="content">
                                <div className="soon_text">
                                  <h5>Create Token </h5>
                                  <span className="before">Soon</span>
                                </div>
                                <p>Create your own Crypto token</p>
                              </div>
                            </Link>
                            {loginCheck == false ? (
                              <Link to="/login">
                                <div className="icon">
                                  <i class="ri-user-add-fill"></i>
                                </div>
                                <div className="content">
                                  <h5>Referral Earning</h5>
                                  <p>Reffer your friends and earn</p>
                                </div>
                              </Link>
                            ) : (
                              <Link to="/refferal">
                                <div className="icon">
                                  <i class="ri-user-add-fill"></i>
                                </div>
                                <div className="content">
                                  <h5>Referral Earning</h5>
                                  <p>Reffer your friends and earn</p>
                                </div>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
                {/* <Link to="/markets">Market</Link>
                <Link to="/trade/ADVB_USDT">Spot</Link>
                {loginCheck == false ? (
                <Link to="/login">P2P</Link>
                ) : (
                  <Link to="/p2p">P2P</Link>
                )}
                <Link to="/soon">Staking</Link>
                <Link to="/soon">Launchpad</Link> */}
              </div>
              {loginCheck == false ? (
                <Link to="/login" className="Butn_header">
                  Login
                </Link>
              ) : (
                ""
              )}
              {loginCheck == false ? (
                <Link to="/register" className="Butn_header mr-0">
                  Register
                </Link>
              ) : (
                ""
              )}

              {loginCheck == true ? (
                <>
                  <Link to="/dashboard" className="Butn_header">
                    Dashboard
                  </Link>

                  <Link to="" className="Butn_header" onClick={logout}>
                    Logout
                  </Link>
                </>
              ) : (
                ""
              )}
              <div className="notofy_icone">
                {loginCheck && loginCheck == true ? (
                  <div className="notification_section  padingrespos ">
                    <Button data-toggle="collapse" data-target="#notification">
                      <i class="ri-notification-2-fill"></i>
                    </Button>
                    <div id="notification" class="collapse">
                      <div className="content_notification">
                        <h3>
                          Notification{" "}
                          <Link to="/notification">View all</Link>
                        </h3>
                        <div className="overflow_seee">
                          {notifications && notifications.length > 0
                            ? notifications.map((item, i) => {
                                return (
                                  <>
                                    <Link to= "/notification"className="msg-noti">
                                      <small>
                                        {Moment(item.createdAt).fromNow()}
                                      </small>
                                      <p>{item.message}</p>
                                    </Link>
                                  </>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                 ""
                )}
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div id="wallet" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Connect Wallet</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="connect_wallet_section">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="wallet_option">
                      <img
                        src={require("../Image/MetaMask_Fox.svg.png")}
                        className="img-fluid"
                      />
                      <p>Metamask</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wallet_option">
                      <img
                        src={require("../Image/TWT.png")}
                        className="img-fluid"
                      />
                      <p>Trust Wallet</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wallet_option">
                      <img
                        src={require("../Image/WalletConnect-icon.png")}
                        className="img-fluid"
                      />
                      <p>Wallet Connect</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
